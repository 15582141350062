import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProfileCardStyle } from "./Molecule.Style";
import parse from 'html-react-parser';
import { getInitial } from "../../constants/constants";

const ServiceProviderCard = ({ profile }) => {
  return (
    <>
      <ProfileCardStyle>
        <Link to={`/service-profile/${profile.id}`}>
          <div className="card-container">
            <div className="img-container">
              {profile.profile_image ? (
                <img className="round" src={profile.profile_image} alt="user" />
              ) : (
                <Initials className="round">{getInitial(profile.company_name)}</Initials>
              )}
            </div>
            <h3>{profile.company_name}</h3>
            <h6>{profile.location}</h6>
            <p className="truncate">
              {parse(profile.company_details.substring(0, 30))}
            </p>
            <div className="buttonss">
              <Button>Read More</Button>
            </div>
            <div className="skills">
              <ul>
                <li>{profile.service_type}</li>
              </ul>
            </div>
          </div>
        </Link>
      </ProfileCardStyle>
    </> //
  );
};

export default ServiceProviderCard;

const Button = styled.button`
  background-color: var(--clr-primary);
  border: 1px solid var(--clr-primary);
  border-radius: 3px;
  color: var(--clr-white);
  font-weight: 500;
  padding: 10px 25px;
  cursor: pointer;
`;

const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  background-color: var(--clr-gray-light); /* Adjust background color as needed */
  border-radius: 50%;
  color: var(--clr-dark); /* Adjust text color as needed */
  font-size: 5rem; /* Adjust font size as needed */
  font-weight: bold;
`;