import React, { useEffect, useState } from "react";
import { Route, Redirect, Navigate, useNavigate, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import { isAuthenticated } from "../../api/authenticationApi";
import axios from "axios";
// import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

const PrivateRoute = ({ children, ...rest }) => {
  const isPaid=useSelector(state=>state.profileReducer.isPaid);
  const {isAuthenticated}=useSelector(state=> state.auth)
  // const location = useLocation();
 console.log("here1")
   if(!isAuthenticated) return ( <Navigate
    replace to= "/login"
  />);
 console.log("here2")
    
  // if(!localStorage.getItem('isPaid') &&isAuthenticated && !isPaid && location.pathname !=='/payment') return( <Navigate
  //   replace to= "/payment"
  // />);
  console.log("here43s")
  console.log(isPaid)
  console.log(isAuthenticated)
  // console.log(location.pathname)

  // if(isAuthenticated && isPaid&&location.pathname ==='/profile/create') return( <Navigate
  // if(isAuthenticated && location.pathname ==='/profile/create') return( <Navigate
  //   replace to= "/profile"
  // />);
 console.log("here100000")

  return children;
};



// PrivateRoute.propTypes = {
//   component: PropTypes.func.isRequired,
//   location: PropTypes.object,
// };

export default PrivateRoute;
