import { BrowserRouter, Routes, Route, HashRouter, Form } from "react-router-dom";
import "./App.css";
import LandingPage from "./components/pages/LandingPage";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import EventDetail from "./components/pages/EventDetail";
import Member from "./components/pages/Member";
import MemberDetail from "./components/pages/MemberDetails";
import Certificate from "./components/pages/Cerificate";
import Login from "./components/pages/Login";
import Blog from "./components/pages/Blog";
import BlogDetail from "./components/pages/BlogDetail";
import Profile from "./components/pages/Profile";
import CreateProfile from "./components/pages/CreateProfile";
import PrivateRoute from "./components/authentication/PrivateRoute";
import ResetPasswordConfirm from "./components/pages/ResetPasswordConfirm";
import ResetPassword from "./components/pages/ResetPassword";
import Activate from "./components/pages/Activate";
import Navbar from "./components/organisms/Navbar";
import Footer from "./components/organisms/Footer";
import Payment from "./components/pages/payment";
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { checkAuthenticated, load_user } from "./redux/actions/auth";
import {isAuthenticated} from './api/authenticationApi';
import { getIsPaid } from "./redux/actions/listProfile";
import Events from "./components/pages/Events";
import Members from "./components/pages/Members";
import AutoScrollToTop from "./components/molecules/AutoScrollToTop";
import RegisterNow from "./components/pages/RegisterNow";
import Banner from "./components/pages/Banner";
import ForNotification from "./components/pages/ForNotification";
import NewsMedia from "./components/pages/NewsMedia";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsandConditions from "./components/pages/TermsandConditions";
import CancellationRefund from "./components/pages/CancellationRefund";
import Forprofile from "./components/authentication/Forprofile";
import ProfileV from "./components/pages/ProfileV";
import Disclaimer from "./components/pages/Disclaimer";
import ServiceProvider from "./components/pages/ServiceProvider";
import ServiceProviderV from "./components/pages/ServiceProviderV";
// import PrivateRoute_forprofile from "./components/authentication/Forprofile";

function App() {
  const dispatch =useDispatch()
      useEffect(()=>{
        if(isAuthenticated()){
            dispatch(checkAuthenticated());
            dispatch(load_user());
            dispatch(getIsPaid());
          }
      },[])

      

  return (
      <HashRouter>
      <AutoScrollToTop />
      <div style={{paddingTop: '1px', minHeight: 'calc(100vh - 80px)'}}>
        <Routes>
          <>
            <Route  exact path="/index" element={<LandingPage />} />
            <Route path="/events/:id" exact element={<EventDetail />} />
            <Route path="/events" exact element={<Events />} />
            <Route path="/members" exact element={<Members />} />
            <Route path="/service-providers" exact element={<ServiceProvider />} />
            <Route path="/profile" element={<Forprofile><Profile /></Forprofile>} />
            <Route path="/profile/create" element={<PrivateRoute><CreateProfile /></PrivateRoute>} />
            <Route path="/profile/update" element={<PrivateRoute><CreateProfile /></PrivateRoute>} />
            <Route path="/profile/:id" exact element={<ProfileV />} />
            <Route path="/service-profile/:id" exact element={<ServiceProviderV />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/register" element={<Member />} />
            <Route path="/memberdetail" element={<PrivateRoute><MemberDetail /></PrivateRoute>}/>
            <Route path="/payment" element={<PrivateRoute><Payment/></PrivateRoute>} />
            <Route path="/certificate" element={<Certificate />} />
            <Route path="/login" element={<Login />} />
            <Route path="/enquire" element={<RegisterNow />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route path="/activate/:uid/:token" element={<Activate />} />
            <Route path="/" element={<Banner />} />
            <Route path="/notification" element={<ForNotification />} />
            <Route path="/newsmedia" element={<NewsMedia />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsandConditions />} />
            <Route path="/cancellation-refund" element={<CancellationRefund />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            </>
        </Routes>
        </div>
      </HashRouter>
    
  );
}

export default App;
