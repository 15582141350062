import React, { useEffect, useState } from "react";
import Arrows from "../atoms/Arrows";
import Dots from "../atoms/Dots";
import ImageSlider from "../atoms/ImageSlider";
import SliderContent from "../molecules/SliderContent";
import { SliderStyle } from "./Organisms.style";

const len = ImageSlider.length - 1;

function Slider(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 6000);
    return () => clearInterval(interval);
  }, [activeIndex]);
  return (
    <>
      <SliderStyle>
        <div className="slider-container">
          <SliderContent activeIndex={activeIndex} imageSlider={ImageSlider} />
          <Arrows
            prevSlide={() =>
              setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
            }
            nextSlide={() =>
              setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
            }
          />
          <Dots
            activeIndex={activeIndex}
            imageSlider={ImageSlider}
            onClick={(activeIndex) => setActiveIndex(activeIndex)}
          />
        </div>
      </SliderStyle>
    </>
  );
}

export default Slider;
