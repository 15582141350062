import React from "react";
import Terms from "../organisms/Terms";

const TermsandConditions = () => {
  return (
    <>
      <Terms />
    </>
  );
};

export default TermsandConditions;
