import React, {useState} from "react";
import {reset_password} from "../../redux/actions/auth"
import { Link, Navigate } from "react-router-dom";
import { connect,useDispatch,useSelector } from "react-redux";
import { LoginSectionStyle } from "../organisms/Organisms.style";
import Footer from "../organisms/Footer";
import Navbar from "../organisms/Navbar";

const ResetPassword = ({reset_password}) => {
  const [requestSent, setRequestSent] = useState(false)

  const [formData, setFormData] = useState({
    email: "",
  });

  const dispatch= useDispatch();
  const errorMessage = useSelector(state=> state.auth.message)
  

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(reset_password(email));   
  };


  return  (
    <>
    <Navbar />
    
      <LoginSectionStyle>
        <div className="container">
          <div className="forms">
            <div className="form login">
              <span className="title">Reset Password</span>
              <br/>
              <p>{errorMessage?<span>{errorMessage}</span>:''}</p> 
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="input-field">
                  <input
                    type="text"
                    placeholder="Enter Email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <div className="checkbox-text">
                  <div className="checkbox-content"></div>
                </div>

                <div className="input-field button">
                  <input type="submit" value="Reset Password" />
                </div>
              </form>
              
            </div>
          </div>
        </div>
      </LoginSectionStyle>
      <Footer />
    </> //
  )
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});


export default connect(null, { reset_password }) (ResetPassword);
