import React from "react";
import TitleSectionThree from "../molecules/TitleSectionThree";
import ContactSection from "../organisms/ContactSection";
import Footer from "../organisms/Footer";
import Navbar from "../organisms/Navbar";

const Contact = () => {
  return (
    <>
    <Navbar/>
      <TitleSectionThree title="Contact Us" imgUrl={`${process.env.PUBLIC_URL}/images/title-back.jpg`}
      />
      <ContactSection />
      <Footer/>
    </>
  );
};

export default Contact;
