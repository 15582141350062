import React, { useState, useEffect } from "react";
import { ProfileSectionStyle } from "./Organisms.style";
import {
  fetchProfile,
  removeProfile,
  getUserProfile,
} from "../../redux/actions/listProfile";
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../molecules/Modal";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import Loader from "../molecules/Loader";
import TitleSectionMembers from "../molecules/TitleSectionMembers";
import parse from 'html-react-parser';
import { getInitial } from "../../constants/constants";
const ProfileSection = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const [profile, setProfile] = useState({});
  const profileData = useSelector((state) => state.profileReducer.profile);
  const userProfile = useSelector((state) => state.profileReducer.me);
  const Isloading = useSelector((state) => state.profileReducer.loading);
  const isUpdated = useSelector((state) => state.profileReducer.isUpdated);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [loadingStarted, setLoadingStarted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchProfileDetail = async () => {
    dispatch(fetchProfile(id));
  };
  useEffect(() => {
    if (Isloading) {
      setLoadingStarted(true);
    }
    if (loadingStarted && !Isloading) {
      setIsLoaded(true);
    }
  }, [Isloading, loadingStarted]);

  useEffect(() => {
    if (id && location.pathname !== "/profile") {
      fetchProfileDetail();
      return () => {
        dispatch(removeProfile());
      };
    }
  }, [id]);

  useEffect(() => {
    if (location.pathname === "/profile") {
      dispatch(getUserProfile());
    }
  }, []);

  useEffect(() => {
    if (id && location.pathname !== "/profile" && !Isloading) {
      setProfile(profileData);
    }

    if (location.pathname === "/profile" && Isloading) {
      if (userProfile.length) {
        setProfile(userProfile[0]);
      } else if (!isUpdated) {
      }
    }
  }, [profileData, userProfile, id, Isloading, isUpdated, navigation, profile]);

  if (!isAuthenticated) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <ProfileSectionStyle>
        {Object.keys(profile).length === 0 ? (
          <Loader />
        ) : (
          <div>
            <TitleSectionMembers
              img={`${process.env.PUBLIC_URL}/images/heroimg.jpg`}
              name={profile.company_name}
            />
            <div className="container">
              <div className="image" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start"
              }}>
                {profile.profile_image ? (
                  <img className="header" src={profile.profile_image} alt="" />
                ) : (
                  <Initials style={{
                    border: "1px solid var(--clr-primary)",
                    borderRadius: "50%",
                    padding: "7px",
                    height: "135px",
                    width: "135px"
                  }}>{getInitial(profile.company_name)}</Initials>
                )}

              </div>
              <div className="text-profile">
                <h2>{profile.company_name}</h2>
                <h3>
                  <b>Contact Name :</b> {profile.name}
                </h3>
                <p>
                  <span>
                    <b>Contact No. :</b> {profile.contact},
                  </span>
                  {profile.company_email ? (
                    <span>
                      <b>Email :</b> {profile.company_email}
                    </span>
                  ) : null}
                </p>
                <p>
                  <b>Address :</b> {profile.address}
                </p>
                <p>
                  <b>Category :</b> {profile.category_field}
                </p>
                <p>
                  <b>About Company :</b>

                  {parse(profile.company_details)}
                </p>

                <div className="buttons-section">
                  {location.pathname === "/profile" && (
                    <div className="button">
                      <Link to="/profile/update">Update</Link>
                    </div>
                  )}
                  {profile.is_varified
                    ? location.pathname !== "/certificate" && (
                      <div className="button">
                        <Link to="/certificate">Certificate</Link>
                      </div>
                    )
                    : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </ProfileSectionStyle>
    </>
  );
};

export default ProfileSection;


const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  background-color: var(--clr-gray-light); /* Adjust background color as needed */
  border-radius: 50%;
  color: var(--clr-dark); /* Adjust text color as needed */
  font-size: 5rem; /* Adjust font size as needed */
  font-weight: bold;
`;