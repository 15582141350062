import {
    SERVICE_PROVIDER_LOAD,
    SERVICE_PROVIDER_LOAD_SUCCESS,
    SERVICE_PROVIDER_LOAD_FAIL,
    USER_SERVICE_PROVIDER_LOAD,
    SINGLE_SERVICE_PROVIDER_LOAD,
    SINGLE_SERVICE_PROVIDER_LOAD_SUCCESS,
    SINGLE_SERVICE_PROVIDER_LOAD_FAIL,
    REMOVE_SERVICE_PROVIDER,
    SERVICE_PROVIDER_CREATE,
    SERVICE_PROVIDER_CREATE_FAIL,
    SERVICE_PROVIDER_CREATE_SUCCESS,
    SERVICE_PROVIDER_UPDATING,
    IS_LOADING,
} from "../actions/types";


export const serviceProviderReducer = (state = { services: [], service: {}, me: {}, isPaid: false, isUpdated: false }, action) => {
    switch (action.type) {
        case IS_LOADING:
            return { ...state, loading: true };
        case SERVICE_PROVIDER_LOAD:
            return { ...state, loading: true };
        case SINGLE_SERVICE_PROVIDER_LOAD_SUCCESS:
            return { ...state, loading: false, service: action.payload };
        case SERVICE_PROVIDER_LOAD_SUCCESS:
            return { ...state, loading: false, services: action.payload };
        case SERVICE_PROVIDER_LOAD_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
