import React from "react";
import { Link } from "react-router-dom";
import { FooterStyle } from "./Organisms.style";
import {
  FiMapPin,
  FiMail,
  FiFacebook,
  FiLinkedin,
  FiTwitter,
} from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <FooterStyle>
        <footer className="footer">
          <div className="footer-distributed">
            <div className="footer-left">
              <h3>
                <img
                  src={`${process.env.PUBLIC_URL}/images/sia-logo.png`}
                  alt=""
                />
              </h3>

              <p className="footer-links">
                <Link to="/" className="link-1">
                  Home
                </Link>

                <Link to="/about">About</Link>

                <Link to="/members">Members</Link>

                <Link to="/events">Events</Link>

                <Link to="/newsmedia">Media</Link>
                <Link to="#">Services</Link>

                <Link to="blog">Blog</Link>
              </p>

              <p className="footer-company-name">SIA Pune © 2022</p>
            </div>

            <div className="footer-center">
              <h2 className="footer-center-contact">Contact Details</h2>
              <div className="footer-contact">
                <FiMapPin className="footer-conatct-icons" />
                <p>Nanded Phata, Sinhgad Road, Pune 411041</p>
              </div>
              <div className="footer-contact">
                <BsTelephone className="footer-conatct-icons" />
                <p>+91 9890908282</p>
              </div>

              <div className="footer-contact">
                <FiMail className="footer-conatct-icons" />
                <p>
                  <Link to="mailto:support@siapune.com">
                    support@siapune.com
                  </Link>
                </p>
              </div>
            </div>

            <div className="footer-right">
              <h2 className="footer-center-contact">About Association</h2>
              <p className="footer-company-about">
                Established in 2021 in Pune, the educational capital of the
                country.
              </p>

              <div className="footer-icons">
                <Link to="#">
                  <FiFacebook />
                </Link>
                <Link to="#">
                  <FiTwitter />
                </Link>
                <Link to="#">
                  <FiLinkedin />
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <ul>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>{" "}
              </li>
              <li>
                <Link to="/cancellation-refund">Cancellation & Refund</Link>{" "}
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>
        </footer>
      </FooterStyle>
    </>
  );
};

export default Footer;
