import React, { useState } from "react";
import { CalenderSectionStyle } from "./Organisms.style";
import { connect, useSelector, useDispatch } from "react-redux";
import { fetchEvents } from "../../redux/actions/events";
import { Link } from "react-router-dom";


import SectionHeading from "../atoms/SectionHeading";
import EventCard from "../molecules/EventCard";
import { useEffect } from "react";

// return (
// <EventCard data={event} key={event.id} />
// );
const CalenderSection = () => {
  const [plimit, setPlimit] = useState(2);
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.events);
  console.log("neweben", events);
  const fetchEvent = async () => {
    dispatch(fetchEvents());
  };
  useEffect(() => {
    if (events) {
      fetchEvent();
    }
  }, []);

  // console.log("event", this.props.events);
  return (
    <>
      <CalenderSectionStyle>
        {events ? (
          <div>
            {
              <div>
              <SectionHeading heading="Upcoming Events" />
              <div className="event-container">
                {events
                  .slice(0, plimit ? plimit : events.length)
                  .map((event) => {
                    return <EventCard data={event} key={event.id} />;
                  })}
              </div>
              </div>
            }
          </div>
        ) : (
          <p>Loading</p>
        )}
        <div className="container-icon">
          {events.length === 0 && (
            <img
              className="no-event-img"
              src={`${process.env.PUBLIC_URL}/images/event-img.png`}
            />
          )}
        </div>
      </CalenderSectionStyle>
    </> //
  );
};

const mapStateToProps = (state, ownProps) => {
  return { events: state.events.events };
};

export default connect(mapStateToProps, { fetchEvents })(CalenderSection);
