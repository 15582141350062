import {
  PROFILE_LOAD,
  PROFILE_LOAD_SUCCESS,
  PROFILE_LOAD_FAIL,
  SINGLE_PROFILE_LOAD_SUCCESS,
  REMOVE_PROFILE,
  USER_PROFILE_LOAD,
  REMOVE_USER_PROFILE,
  USER_PROFILE_UPDATING,
  USER_PROFILE_UPDATE_SUCCESSFUL,
  GET_IS_PAID_LOADED,
  SET_IS_PAID,
  IS_LOADING,
  PROFILE_CREATE_SUCCESS,
  BANEER_LOAD_SUCCESS,
  BANNER_LOAD_FAIL,
  REMOVE_ME,
} from "../actions/types";

export const profileReducer = (state = { profiles: [] ,profile:{},me:{},isPaid:false,isUpdated:false}, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {...state, loading: true };
    case PROFILE_LOAD:
      return {...state, loading: true };
    case SINGLE_PROFILE_LOAD_SUCCESS:
      return {...state, loading: false,profile:action.payload};
    case REMOVE_PROFILE:
      return {...state,profile:action.payload};
    case USER_PROFILE_LOAD :
      return {...state,me:action.payload};
    case REMOVE_USER_PROFILE:
      return {...state,me:action.payload};
    case REMOVE_ME:
      return {...state,me:{}};
    case PROFILE_LOAD_SUCCESS:
      return { ...state,loading: false, profiles: action.payload };
    case PROFILE_LOAD_FAIL:
      return {...state, loading: false, error: action.payload };
    case USER_PROFILE_UPDATING:
        return {...state, loading: true, };
     case USER_PROFILE_UPDATE_SUCCESSFUL:
          return {...state, loading: false, isUpdated:true};
    case GET_IS_PAID_LOADED:
    case SET_IS_PAID:
      localStorage.setItem('isPaid',action.payload)
      return {...state, loading: false,isPaid:action.payload };
    case PROFILE_CREATE_SUCCESS:
      return {...state,isUpdated:true}
    default:
      return state;
  }
};
