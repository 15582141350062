import React, { useState } from "react";
import Axios from "axios";
import { MemberSignupStyle } from "./Organisms.style";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsPaid } from "../../redux/actions/listProfile";
import { useEffect } from "react";
const Pay = () => {
  // for payment
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [paid, setpaid] = useState(false);
  useEffect(() => {
 if(paid===true){
console.log("here2")

  navigation("/profile/create", { replace: true });
 }
  }, [paid]);
  
  // this function will handel payment when user submit his/her money
  // and it will confim if payment is successfull or not

  const handlePaymentSuccess = async (response) => {
    try {
      let bodyData = new FormData();

      // we will send the response we've got from razorpay to the backend to validate the payment
      bodyData.append("response", JSON.stringify(response));

      await Axios({
        url: `${process.env.REACT_APP_API_URL}/api/payment/success/`,
        method: "POST",
        data: bodyData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setName("");
          setAmount("");
          dispatch(setIsPaid(true));
          console.log(true)
console.log("here1")

          navigation("/profile/create", { replace: true });
          //  window.location.replace('/#/profile/create')
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(console.error());
    }
  };

  // this will load a script tag which will open up Razorpay payment card to make //transactions
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };
  const showRazorpay = async () => {
    let bodyData = new FormData();

    // we will pass the amount and product name to the backend using form data
    bodyData.append("amount", 100);
    bodyData.append("name", "testing");

    const data = await Axios({
      url: `${process.env.REACT_APP_API_URL}/api/startpayment`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      data: bodyData,
    }).then((res) => {
      return res;
    });
console.log("here")
    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user

    var options = {
      key_id: process.env.REACT_APP_PUBLIC_KEY, // in react your environment variable must start with REACT_APP_
      key_secret: process.env.REACT_APP_SECRET_KEY,
      amount: data.data.payment.amount,
      currency: "INR",
      name: "Org. Name",
      description: "Test teansaction",
      image: "", // add image url
      order_id: data.data.payment.id,
      handler: function (response) {
        // we will handle success by calling handlePaymentSuccess method and
        // will pass the response that we've got from razorpay
console.log("here4")

        handlePaymentSuccess(response);
      },
      prefill: {
        name: "User's name",
        email: "User's email",
        contact: "User's phone",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    loadScript();
  }, []);

  return (
    <MemberSignupStyle>
      <div className="input-field button">
        <input type="submit" onClick={showRazorpay} value="Pay Now" />
      </div>
    </MemberSignupStyle>
  );
};

export default Pay;
