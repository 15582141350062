import React, { useState, useEffect, useRef } from "react";
import { ContactSectionStyle } from "./Organisms.style";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { BsFillMapFill, BsMap, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import Axios from "axios";

const ContactSection = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    content: "",
  });
  // data = formvalue
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const formRef = useRef();

  const handleChange = (event) => {
    // const {name, value}= event.target;
    // setData({...data, [name]: value});
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
    // formRef.current?.reset()   //not working
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(validationform(data));
    setIsSubmit(true);
  };
  const validationform = (value) => {
    const errors = {};
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isphone =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    if (!value.name) {
      errors.name = "Please enter name";
    }
    if (!value.email) {
      errors.email = "Please enter email";
    } else if (!emailPattern.test(value.email)) {
      errors.email = "Enter valid email";
    }
    if (!value.phone) {
      errors.phone = "Please enter phone";
    } else if (!isphone.test(value.phone)) {
      errors.phone = "Please enter valid phone number";
    }
    if (!value.content) {
      errors.content = "Please enter Message";
    }
    return errors;
  };
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      Axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, {
        name: data.name,
        email: data.email,
        phone: data.phone,
        content: data.content,
      }).then((res) => {
        console.log("submit", res.data);
      });
    }
  }, [formError]);

  return (
    <>
      <ContactSectionStyle>
        <section className="contact_us">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="contact_inner">
                  <div className="contact-row">
                    <div className="col-md-10">
                      <div className="contact_form_inner">
                        <div className="contact_field contact-form-fields">
                          <h3>Contact Us</h3>
                          <p>
                            Feel Free to contact us any time. We will get back
                            to you as soon as we can!.
                          </p>
                          {Object.keys(formError).length === 0 && isSubmit ? (
                            <div className="alert">
                              Form Submitted Successfully
                            </div>
                          ) : null}
                          <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="contact-field">
                              <div className="forminput">
                                <input
                                  type="text"
                                  className="form-control form-group"
                                  placeholder="Name"
                                  name="name"
                                  value={data.name}
                                  onChange={handleChange}
                                />
                                <span>{formError.name}</span>
                              </div>
                              {/*<div className="forminput">
                                <input
                                  type="text"
                                  className="form-control form-group"
                                  placeholder="Company Name"
                                  name="name"
                                  value={data.name}
                                  onChange={handleChange}
                                />
                                <span>{formError.name}</span>
  </div> */}
                            </div>
                            <div className="contact-field">
                              <div className="forminput">
                                <input
                                  type="text"
                                  className="form-control form-group"
                                  placeholder="Email"
                                  name="email"
                                  value={data.email}
                                  onChange={handleChange}
                                />
                                <span>{formError.email}</span>
                              </div>
                            </div>
                            <div className="contact-field">
                              <div className="forminput">
                                <input
                                  type="text"
                                  className="form-control form-group"
                                  placeholder="Phone Number"
                                  name="phone"
                                  value={data.phone}
                                  onChange={handleChange}
                                />
                                <span>{formError.phone}</span>
                              </div>
                            </div>
                            <div className="contact-field">
                              <div className="forminput">
                                <textarea
                                  className="form-control form-group"
                                  placeholder="Message"
                                  name="content"
                                  value={data.content}
                                  onChange={handleChange}></textarea>
                                <span>{formError.content}</span>
                              </div>
                            </div>
                            <button
                              className="contact_form_submit"
                              ref={formRef}>
                              Send
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="right_conatct_social_icon d-flex align-items-end">
                        <div className="socil_item_inner d-flex">
                          <li>
                            <a href="#">
                              <i className="fab fa-facebook-square"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact_info_sec">
                    <h4>Contact Info</h4>
                    <div className="info_single">
                      <BsTelephone className="contact-icons" />
                      <span>+91 8009 054294</span>
                    </div>
                    <div className="info_single">
                      <AiOutlineMail className="contact-icons" />
                      <span>info@siapune.com</span>
                    </div>
                    <div className="info_single">
                      <BsMap className="contact-icons" />
                      <span>Nanded Phata, Pune 411041</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContactSectionStyle>
    </>
  );
};

export default ContactSection;
