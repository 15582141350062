import React from "react";
import { AboutSectionStyle } from "./Organisms.style";

const AboutSection = () => {
  return (
    <>
      <AboutSectionStyle>
        <div className="about-container">
          <div className="about-section">
            <div className="inner-container">
              <h1>Empowering Entrepreneurs</h1>
              <p className="text">
                The world over, micro, small and medium enterprises (SMEs) are
                recognized as a dynamic sector and credited with the creation of
                over 80 percent of total employment. In India, SMEs have created
                480 million jobs while the organized sector has created 29
                million jobs. This vital sector embodies the entrepreneurial
                spirit and is represented by the All India Association of
                Industries(SIA), its core mission being ‘building bridges of
                prosperity through industry’
              </p>
              <div>
                <span className="skills">Our Vision</span>
                <p>
                  We aspire to convert current SIA in to the preferred
                  destination for industrial and trade activities.
                </p>
                <span className="skills">Our Mission</span>
                <p>
                  We act as communication chanel for the opportunities in the
                  industries and trade sector. Altimate goal is to witness the
                  economic development and improved living standard.
                </p>
              </div>
            </div>
          </div>
          <div className="about-section-nxt">
            <div className="inner-container-nxt">
              <p className="text">
                Established in 2021 in Pune, the educational capital of the
                country. The SIA has over 1500 members and through its
                affiliates it represents over 50,000 industries. Nearly 70
                percent of its members are from the SME sector. With sweeping
                changes brought about by rapid globalization, mergers and
                takeovers having become the order of the day, with new
                challenges for industry to a large extent SIA meets the
                requirement of SMEs in this process.
              </p>
              <h1>Our Objectives</h1>
              <ul className="text">
                <li>
                  To attempt to bring every micro , small and medium enterprise
                  within a membership fold of the SIA{" "}
                </li>
                <li>
                  To promote and maintain the co- operation among the individual
                  members of SIA
                </li>
                <li>
                  To promote the development of micro , small and medium
                  enterprise under the scope of SIA
                </li>
              </ul>
              <h1>Our Values</h1>
              <p className="text">
                1. Doing it together - foundation value of SIA. <br />
                2. Professionalism - key to success in achieving SIA mission.{" "}
                <br />
                3. Respect - Baseline of all SIA efforts.
              </p>
              <h1>Core Functions</h1>
              <p className="text">
                The SIA acts as a catalyst for industrial growth.
              </p>
              <p className="text">
                Our activities includes organizing seminars, workshops, trade
                fairs, business meetings, trade delegations, economic and
                business forum to provide information and promote domestic and
                international trade, investment and technology transfers through
                networking.
              </p>
              <h1>Policy Information</h1>
              <p className="text">
                The Government frequently seeks its views on Free Trade
                Agreements, labour law reforms, FDI flows into domestic
                industrial sector and also on fiscal and monetary measure. SIA
                aims to not only provide a platform for development to the SME
                sector, but to also participate in proactive decision making to
                accelerate growth and help members surmount obstacles to doing
                business.
              </p>
              <p className="text">
                SIA nominees find place in government and semi-government panels
                and other policy making bodies. SIA is represented on various
                Advisory Committees of the Government, Reserve Bank of India,
                SIDBI and JNPT.
              </p>
              <h1>Supplementary Functions</h1>
              <p className="text">
                SIA aims to publish books and papers on important issues of MSME
              </p>
              <h1>Through the ages</h1>
              <p className="text">
                Today, the Sinhgad Industrial Association (SIA) is led by Mr.
                Vijay Kalantri as the President with Ms. Sangeeta Jain as the
                Senior Director
              </p>
              <p className="text"></p>
              <p className="text"></p>
              <p className="text"></p>
            </div>
          </div>
        </div>
      </AboutSectionStyle>
    </>
  );
};

export default AboutSection;
