import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const UserAuth = ({ onLogout, userName }) => {
  return (
    <>
      <li>
        <Link to="/profile">PROFILE</Link>{" "}
      </li>
      <li className="auth-login">
        <Link to="#">
          Welcome:
          <strong className="auth-login-name">
            {/* {localStorage.getItem("username")} */}
            {userName}
          </strong>
        </Link>
      </li>
      <li>
        <Link to="#" onClick={onLogout}>
          Logout
        </Link>
      </li>
    </>
  );
};

export default UserAuth;
