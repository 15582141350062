import React from 'react';
import MemberSignup from '../organisms/MemberSignup';
import { MemberSignupStyle } from '../organisms/Organisms.style';
import Pay from '../organisms/pay';
const Payment =()=>{

  return (
    <MemberSignupStyle>
    <div>
    <div className="container">
      <div className="forms">
        <div className="form login">
        <Pay/>
        </div>
    </div>
    </div>
    </div>
    </MemberSignupStyle>
  )
}

export default Payment;