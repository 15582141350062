import React, { useEffect, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { NavbarStyle } from "./Organisms.style";
import { connect, useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions/auth";
import UserAuth from "../atoms/UserAuth";
import { isAuthenticated } from "../../api/authenticationApi";
import { AiOutlineAlignRight } from "react-icons/ai";

const Navbar = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  
  const data = useSelector((state) => state?.auth?.user);
  // const [namev, setNamev] = useState();
  // us can use UserAuth component for gestlink logic or u can write logic below

  const GuestLinks = () => (
    <>
      <li>
        <NavLink className="nav-link" to="/login">Login</NavLink>
      </li>
    
    </> //
  );

  const authLinks = () => {
    <li>
      <a href="#!" className="nav-link" onClick={logout}>
        Logout
      </a>
    </li>;
  };

  return (
    <NavbarStyle>
    <nav class="navbar">
    <div class="navbar-container container">
      <input type="checkbox" name="" id="" />
      <div class="hamburger-lines">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
      <ul class="menu-items">
      <li>
            <NavLink className="nav-link" to="/index">Home</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/about">About</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/members">Members</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/service-providers">Service Providers</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/events">Events</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/contact">Contact</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/enquire">Register</NavLink>
          </li>
          {isAuthenticated() ? (
            <UserAuth onLogout={handleLogout} userName={data?.username} />
          ) : (
            <GuestLinks />
          )}

          {/* <UserAuth /> */}
      </ul>
      <img
              className="logo-img"
              src={`${process.env.PUBLIC_URL}/images/sia-logo.png`}
              alt=""
            />
    </div>
  </nav>
    </NavbarStyle>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Navbar);
