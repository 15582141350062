import React from "react";
import SectionHeading from "../atoms/SectionHeading";

const NewsMediaComp = ({ media }) => {
  console.log(media);

  return (
    <>
      {media ? (
        <div>
          {
            <div>
              <SectionHeading heading="Media" />
              <div className="section-media">
                {media.map((item, index) => {
                  return (
                    <div>
                      <img src={item.image} alt="" />
                      <h2>{item.title}</h2>
                      <p>{item.descrption}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </div>
      ) : (
        <p>Loading</p>
      )}
      <div className="noevent">
        {media.length === 0 && (
          <img
            className="no-event-img"
            src={`${process.env.PUBLIC_URL}/images/event-img.png`}
          />
        )}
      </div>
    </>
  );
};

export default NewsMediaComp;
