import React from "react";

const Privacy = () => {
  return (
    <>
      <article
        className="markdown-body entry-content container-lg"
        itemProp="text"
        style={{ maxWidth: "900px", margin: "5rem auto" }}>
        <h2 dir="auto">
          <a
            id="user-content-privacy-policy"
            className="anchor"
            aria-hidden="true"
            href="#privacy-policy"></a>
          Privacy Policy
        </h2>
        <h3 dir="auto">Introduction</h3>
        <p dir="auto">
          Our privacy policy will help you understand what information we
          collect at <em>Sinhgad Industrial Association</em>, how{" "}
          <em>Sinhgad Industrial Association</em> uses it, and what choices you
          have.
          <em>Sinhgad Industrial Association</em> built the{" "}
          <em>Sinhgad Industrial Association</em> app as a free app. This
          SERVICE is provided by <em>Sinhgad Industrial Association</em> at no
          cost and is intended for use as is. If you choose to use our Service,
          then you agree to the collection and use of information in relation
          with this policy. The Personal Information that we collect are used
          for providing and improving the Service. We will not use or share your
          information with anyone except as described in this Privacy Policy.
          <br />
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible in our website, unless
          otherwise defined in this Privacy Policy.
        </p>
        <h3 dir="auto">Information Collection and Use</h3>
        <p dir="auto">
          For a better experience while using our Service, we may require you to
          provide us with certain personally identifiable information, including
          but not limited to users name, email address, gender, location,
          pictures. The information that we request will be retained by us and
          used as described in this privacy policy.
          <br />
          The app does use third party services that may collect information
          used to identify you.
        </p>
        <h3 dir="auto">Cookies</h3>
        <p dir="auto">
          Cookies are files with small amount of data that is commonly used an
          anonymous unique identifier. These are sent to your browser from the
          website that you visit and are stored on your devices’s internal
          memory.
        </p>
        <p dir="auto">
          This Services does not uses these “cookies” explicitly. However, the
          app may use third party code and libraries that use “cookies” to
          collection information and to improve their services. You have the
          option to either accept or refuse these cookies, and know when a
          cookie is being sent to your device. If you choose to refuse our
          cookies, you may not be able to use some portions of this Service.
        </p>
        <h3 dir="auto">Location Information</h3>
        <p dir="auto">
          Some of the services may use location information transmitted from
          users' mobile phones. We only use this information within the scope
          necessary for the designated service.
        </p>
        <h3 dir="auto">Device Information</h3>
        <p dir="auto">
          We collect information from your device in some cases. The information
          will be utilized for the provision of better service and to prevent
          fraudulent acts. Additionally, such information will not include that
          which will identify the individual user.
        </p>
        <h3 dir="auto">Service Providers</h3>
        <p dir="auto">
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul dir="auto">
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p dir="auto">
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>
        <h3 dir="auto">Security</h3>
        <p dir="auto">
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>
        <h3 dir="auto">Children’s Privacy</h3>
        <p dir="auto">
          This Services do not address anyone under the age of 13. We do not
          knowingly collect personal identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>
        <h3 dir="auto">Changes to This Privacy Policy</h3>
        <p dir="auto">
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately, after they are posted
          on this page.
        </p>
        <h3 dir="auto">Contact Us</h3>
        <p dir="auto">
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us.
          <br />
          Contact Information:
          <br />
          Email:
          <em>
            [<a href="mailto:support@siapune.com">support@siapune.com</a>]
          </em>
        </p>
      </article>
    </>
  );
};

export default Privacy;
