
import React from 'react'
import BlogDetailSection from '../organisms/BlogDetailSection'
import Footer from '../organisms/Footer'

import Navbar from '../organisms/Navbar'


const BlogDetail = () => {
    
  return (
      <>
      <Navbar />
       <BlogDetailSection/>
       <Footer/>
       </>
   
  )
}

export default BlogDetail