import React, { useEffect, useState } from "react";
import Modal from "../molecules/Modal";
import { Link } from "react-router-dom";
import { CertificateSectionStyle } from "./Organisms.style";
import { useSelector } from "react-redux";
// Authorization: `Bearer ${localStorage.getItem("access_token")}`,
const CertificateSection = () => {
  const [certificateData, setCertificateData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const userProfile = useSelector((state) => state.profileReducer.me);
  const fetchCertificateData = (token) => {
    
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("access_token")}`  
      );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/api/certificate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setCertificateData(result))

      .catch((error) => console.log("error", error));
  };
  useEffect(()=>{
    fetchCertificateData()
   },[])


   


  const actions = (
    <>
      <button onClick={window.print}>
        <Link to="">Print</Link>{" "}
      </button>
    </>
    //
  );



  return (
    <>
      <Modal
        certificateData={certificateData} userProfile={userProfile}
        actions={actions} showModal={showModal} setShowModal={setShowModal}
      />
      <div style={{ height: "60vh" }}></div>
    </>
  );
};

export default CertificateSection;
