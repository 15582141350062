import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import styled from "styled-components";

function Loader({ loading, size }) {
  return (
    <>
      <Style>
        <div className="container-loader">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#0099d9", "#57ba52", "#ed3338", "#f58733", "#849b87"]}
          />
        </div>
      </Style>
    </> //
  );
}

export default Loader;

const Style = styled.div`
  height: 70vh;
  .container-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10rem;
  }
`;
