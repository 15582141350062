import React, { useEffect } from "react";
import ProfileSection from "../organisms/ProfileSection";
import { connect } from "react-redux";
import { checkAuthenticated, load_user } from "../../redux/actions/auth";
import TitleSectionThree from "../molecules/TitleSectionThree";
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import ProfileSecV from "./ProfileSecV";

const ProfileV = (props) => {
  useEffect(() => {
    props.checkAuthenticated();
    props.load_user();
  }, []);

  return (
    <>
    <Navbar show={"show"}/>
      <ProfileSecV />
    <Footer/>
    </>
  );
};

export default connect(null, { checkAuthenticated, load_user })(ProfileV);