import React, { useState, useEffect } from "react";
import { load_banner } from "../../redux/actions/BannerAct";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Slider from "../organisms/slider/Slider";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

const Banner = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch();
  const {items, loading, error} = useSelector((state) => state.bannerReducer);
  console.log("banerror", error)
  console.log("bannerselector", items);

  const fetchBanner = async () => {
    if (error) {
     return alert.error(error) 
    }
    dispatch(load_banner());
  };
  console.log("banner", fetchBanner);
  useEffect(() => {
    fetchBanner();
  }, []);

  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/index')
    }, 20000)
  }, [])



  const getSliders = (items) => {
  const slider = [];
  if (items.image1) slider.push({ url: items.image1 });
  if (items.image2) slider.push({ url: items.image2 });
  if (items.image3) slider.push({ url: items.image3 });
  if (items.image4) slider.push({ url: items.image4 });
  if (items.image5) slider.push({ url: items.image5 });
  return slider;
};



  return (
    <>
    <Container>
    <div>
    {(items && items.length > 0) ?  <Slider imageSlider={getSliders(items[0])}  /> : <Loader> <ClipLoader
              size={30}
              color={"#0099d9"}
              className="loading-loader"
              loading={isLoading}
            />
            </Loader>}
    </div>
    <BtnContainer>
    <Button> <Link to="/">Continue To Website</Link> </Button>
    </BtnContainer>
    </Container>
      
    </> //
  );
};

// const mapStateToProps = (state) => ({
//   isAuthenticated: state.auth.isAuthenticated,
// });

export default connect(null, { load_banner })(Banner);


const Container = styled.div`
  /* display: flex; */
  /* height:100vh; */
  height: 100vh;
 
`
const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  height: 100vh;
`;
const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -.5rem;
`;

const Button = styled.button`
  min-width: 100px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  background: var(--clr-primary);
  color: #fff;
  font-size: 1.5rem;
  z-index: 10;
  cursor: pointer;
  a{
    text-decoration: none;
    color: #fff;
  }
`;
