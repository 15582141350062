import React from 'react'
import Footer from '../organisms/Footer'
import LoginSection from '../organisms/LoginSection'
import Navbar from '../organisms/Navbar'


const Login = () => {
    
  return (
      <>
      <Navbar />
       <LoginSection/>
       <Footer/>
       </>
   
  )
}

export default Login