import React from "react";
import TitleSectionThree from "../molecules/TitleSectionThree";
import AboutSection from "../organisms/AboutSection";
import Footer from "../organisms/Footer";
import Navbar from "../organisms/Navbar";

const About = () => {
  return (
    <>
    <Navbar />
    <TitleSectionThree 
    title="About Us"
    imgUrl={`${process.env.PUBLIC_URL}/images/title-back.jpg`}
    />
      <AboutSection />
      <Footer />
    </>
  );
};

export default About;
