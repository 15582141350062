import _ from 'lodash';
import { LOAD_EVENTS, LOAD_EVENT, LOAD_EVENTS_SUCCESS, LOAD_EVENTS_FAIL } from "../actions/types";





// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {events:[],event:{}}, action) => {
    switch (action.type) {
        case LOAD_EVENTS:
            return { ...state, events:action.payload };
        case LOAD_EVENT:
            return { ...state, event:{...action.payload} };
            // return { ...state, [action.payload.data.id]: action.payload.data };
        default:
            return state;
    }
}

// export const selectedEventReducer = (state={}, {type, payload}) => {
//     switch (type) {
//         case LOAD_EVENT:
//             return {...state, ...payload};
//         default:
//             return state;
//     }
// }




// const eventReducer =
// export const eventReducer = (state = { events: [] }, action) => {
//     switch (action.type) {
//       case LOAD_EVENTS:
//         return { loading: true, events: [] };
//       case LOAD_EVENTS_SUCCESS:
//         return { loading: false, profiles: action.payload };
//       case LOAD_EVENTS_FAIL:
//         return { loading: false, error: action.payload };
//       default:
//         return state;
//     }
//   };
