import React, { useState } from "react";
import { DropdownSelectStyle } from "./Molecule.Style";

const DropdownSelectService = ({ category, data, onItemClick }) => {
  const [ selected, setSelected ] = useState("")
  console.log("dataa", data)
  const [isActive, setIsActive] = useState(false);
  console.log("active", selected);
  return (
    <>
      <DropdownSelectStyle>
        <div className="dropdown" onClick={(e) => e.stopPropagation()}>
          <div
            className="dropdown-btn"
            onClick={(e) => {
              setIsActive(!isActive); 
            }}>
            <span className="fas fa-caret-down">
            {selected ? selected : <p>Service Type</p>}
            </span>
          </div>
          {isActive && (
            <div className="dropdown-content">
              {category.map((item, index) => (
                <div
                  onClick={(e) => {
                    setSelected(item);
                    onItemClick(item);
                    setIsActive(false);
                    
                  }}
                  key={index}
                  className="dropdown-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      </DropdownSelectStyle>
    </>
  );
};

export default DropdownSelectService;
