import React, { useState, useRef, useEffect } from "react";
import MemberForm from "../molecules/MemberForm";
import { MemberSignupStyle } from "./Organisms.style";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { signup } from "../../redux/actions/auth";
import axiosAPI from "../../api/axios";
import axios from "axios";
import { useNavigate, Navigate, Link } from "react-router-dom";
import ErrorMessage from "../atoms/ErrorMessage";

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_\.]{1,20}$/;
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


const MemberSignup = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const initialFormData = Object.freeze({
    username: "",
    email: "",
    password: "",
    password2: "",
  });
  const [formValues, setFormValues] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const errors = useSelector((state) => state.auth.error);
  const [errorMessage, setErrorMessage] = useState('');
  // for payment
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");

  // this function will handel payment when user submit his/her money
  // and it will confim if payment is successfull or not
  const handlePaymentSuccess = async (response) => {
    try {
      let bodyData = new FormData();

      // we will send the response we've got from razorpay to the backend to validate the payment
      bodyData.append("response", JSON.stringify(response));

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/payment/success/`,
        method: "POST",
        data: bodyData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log("Everything is OK!");
          setName("");
          setAmount("");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(console.error());
    }
  };

  // this will load a script tag which will open up Razorpay payment card to make //transactions
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const showRazorpay = async () => {
    const res = await loadScript();

    let bodyData = new FormData();

    // we will pass the amount and product name to the backend using form data
    bodyData.append("amount", 100);
    bodyData.append("name", "testing");

    const data = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/startpayment`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      data: bodyData,
    }).then((res) => {
      return res;
    });

    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user

    var options = {
      key_id: process.env.REACT_APP_PUBLIC_KEY, // in react your environment variable must start with REACT_APP_
      key_secret: process.env.REACT_APP_SECRET_KEY,
      amount: data.data.payment.amount,
      currency: "INR",
      name: "Org. Name",
      description: "Test teansaction",
      image: "", // add image url
      order_id: data.data.payment.id,
      handler: function (response) {
        // we will handle success by calling handlePaymentSuccess method and
        // will pass the response that we've got from razorpay
        handlePaymentSuccess(response);
      },
      prefill: {
        name: "User's name",
        email: "User's email",
        contact: "User's phone",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  // payment end

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = "Enter Valid Email Address";
    }
    if (!values.password) {
      errors.password = "password is required";
    } else if (!PWD_REGEX.test(values.password)) {
      errors.password =
        "password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character";
    }
    if (!values.password2) {
      errors.password2 = "password is required";
    } else if (values.password !== values.password2) {
      errors.password2 = "password does not match";
    }
    return errors;
  };

  useEffect(() => {
    // console.log("formErrors", formErrors);

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, []);

  useEffect(() => {
    // Only set error message if errors exist
    if (errors && (errors.username || errors.email)) {
      setErrorMessage(errors.username ? errors.username[0] : errors.email[0]);
    }
  }, [errors]);

  const handleCloseError = () => {
    setErrorMessage('');
  };


  const submit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const errors = validate(formValues);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    try {
      await dispatch(
        signup(
          formValues.username,
          formValues.email,
          formValues.password,
          formValues.password2
        )
      );
    } catch (error) {
      console.error("Submission error:", error.message || "An error occurred during signup.");
    }
  };




  // console.log("isAuthenticated()", isAuthenticated);
  if (isAuthenticated) return <Navigate replace to="/profile/create" />;

  return (
    <>
      <MemberSignupStyle>
        <div>
          <div className="container">
            <div className="forms">
              <div className="form login">
                <span className="title">Signup</span>
                {errorMessage && (
                  <ErrorMessage message={errorMessage} onClose={handleCloseError} />
                )}
                <form onSubmit={submit}>
                  <div className="input-field">
                    <input
                      type="text"
                      placeholder="Enter Username"
                      id="username"
                      name="username"
                      onChange={handleChange}
                      value={formValues.username}
                    />
                  </div>
                  <p className="form-errors">{formErrors.username}</p>
                  <div className="input-field">
                    <input
                      type="text"
                      placeholder="Enter your email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={formValues.email}
                    />
                  </div>
                  <p className="form-errors">{formErrors.email}</p>
                  <div className="input-field">
                    <input
                      type="password"
                      className="password"
                      name="password"
                      placeholder="Enter your password"
                      onChange={handleChange}
                      value={formValues.password}
                    />

                    <i className="uil uil-eye-slash showHidePw"></i>
                  </div>
                  <p className="form-errors">{formErrors.password}</p>
                  <div className="input-field">
                    <input
                      type="password"
                      className="password"
                      name="password2"
                      placeholder="Reenter your password"
                      onChange={handleChange}
                      value={formValues.password2}
                    />

                    <i className="uil uil-eye-slash showHidePw"></i>
                  </div>
                  <p className="form-errors">{formErrors.password2}</p>

                  <div className="checkbox-text">
                    <div className="checkbox-content"></div>
                  </div>

                  <div className="input-field button">
                    <input
                      type="submit"
                      // onClick={showRazorpay}
                      value="Sign up"
                    />
                  </div>
                </form>
                <p>
                  Already have account?
                  <Link to="/login"> Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </MemberSignupStyle>
    </>
    //
  );
};

MemberSignup.propTypes = {
  loginUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  // validate: validate,
};

export default MemberSignup;
