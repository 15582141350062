import styled from "styled-components";

export const CtaButtonStyle = styled.div`
  .btn--primary--solid {
    background-color: var(--clr-white);
    color: var(--clr-primary);
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
  }
  .btn--primary--solid:hover {
    background-color: var(--clr-white);
    color: var(--clr-primary);
    border: none;
  }
  a {
    text-decoration: none;
    color: inherit;
  }

  .btn--warning--solid {
    background-color: var(--clr-light-blue);
    color: var(--clr-white);
    border-radius: 0.2rem;
    border: none;
  }

  .btn--danger--solid {
    background-color: var(--clr-blue);
    color: var(--clr-white);
    border: none;
  }

  .btn--success--solid {
    background-color: var(--clr-success);
    color: var(--clr-white);
    border: none;
  }

  .btn--primary--outline {
    background-color: transparent;
    border: 2px solid var(--clr-secondary);
    color: var(--clr-secondary);
  }

  .btn--warning--outline {
    background-color: transparent;
    border: 2px solid var(--clr-warning);
    color: var(--clr-warning);
  }

  .btn--danger--outline {
    background-color: transparent;
    border: 2px solid var(--clr-danger);
    color: var(--clr-danger);
  }

  .btn--success--outline {
    background-color: transparent;
    border: 2px solid var(--clr-success);
    color: var(--clr-success);
  }

  /* button sizes */
  .btn--medium {
    padding: 0.8rem 3rem;
    font-size: 1rem;
    font-weight: 500;
    
  }

  .btn--large {
    padding: 1rem 1.8rem;
    font-size: 1.2rem;
    
  }

  .btn--small {
    padding: 0.5rem 1.2rem;
    font-size: 1rem;
    border: none;
  }
`;

export const SectionHeadingStyle = styled.div`
.heading-container {
    margin-top: 2rem;
    padding-top: 2rem;
  }
  h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  h1 em {
    font-style: normal;
    font-weight: 600;
  }

  .heading-container h1 {
    text-align: center;
    color: #222;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    word-spacing: 1px;
    letter-spacing: 2px;
    color: #000000;
  }
  .heading-container h1 span {
    line-height: 2em;
    padding-bottom: 15px;
    text-transform: none;
    font-size: 0.7em;
    font-weight: normal;
    font-style: italic;
    font-family: "Playfair Display", "Bookman", serif;
    color: #999;
    letter-spacing: -0.005em;
    word-spacing: 1px;
    letter-spacing: none;
  }
  .heading-container h1:after,
  .heading-container h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45px;
    height: 4px;
    content: "";
    right: 45px;
    margin: auto;
    background-color: #ccc;
  }
  .heading-container h1:before {
    background-color: var(--clr-primary);
    left: 45px;
    width: 90px;
  }
`;