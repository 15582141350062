import React, { Component } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { SearchStyle } from "./Organisms.style";

export class Search extends Component {
  constructor() {
    super();
    this.state = {
      searchData: null,
      noData: false,
    };
  }

  search(key) {
    console.warn("key", key);
    fetch(`${process.env.REACT_APP_API_URL}/api/filters?search=${key}`)
      .then((data) => data.json())
      .then((resp) => {
        console.warn("res: ", resp);
        if (resp.length > 0) {
          this.setState({ noData: false });
          this.props.setSearchData(resp);
        } else {
          this.setState({ noData: true });
          this.props.setSearchData([]);
        }
      });
  }

  render() {
    return (
      <SearchStyle>
        <div>
          <div className="search-container">
            <div className="container">
              <div className="search-box">
                <input
                  type="text"
                  className="search-input"
                  onChange={(event) => this.search(event.target.value)}
                  placeholder="Search.."
                />

                <button className="search-button">
                  <AiOutlineSearch className="search-icon" />
                </button>
              </div>
            </div>
          </div>
          <div>
            {/* Add any additional elements or notifications here */}
          </div>
        </div>
      </SearchStyle>
    );
  }
}

export default Search;
