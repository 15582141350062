import React from 'react'
import { CalenderSectionStyle } from "../organisms/Organisms.style";
import { connect } from "react-redux";
import { fetchEvents } from "../../redux/actions/events";
import { Link } from "react-router-dom";
import { GoLocation, GoCalendar } from "react-icons/go";
import { GrFormNext } from "react-icons/gr";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdOutlineDateRange } from "react-icons/md";
import { BsClipboardCheck, BsCalendar2Event, } from "react-icons/bs";

import SectionHeading from "../atoms/SectionHeading";
import TitleSectionThree from '../molecules/TitleSectionThree';
import Navbar from '../organisms/Navbar';
import Footer from '../organisms/Footer';
import EventCard from '../molecules/EventCard';

class Events extends React.Component {
  componentDidMount() {
    this.props.fetchEvents();
  }

  renderEvents() {
    console.log(this.props.events);
    return this.props.events.map((event) => {
      return (
        
        <div key={event.id}>
        <Link to={`/events/${event.id}`} >
          <EventCard data={event} />
          </Link>
        </div>
      );
    });
  }

  render() {
    // console.log("event", this.props.events);
    return (
      <>
      <Navbar />
        <CalenderSectionStyle>
          {
            <div>
            <TitleSectionThree title="Events" imgUrl={`${process.env.PUBLIC_URL}/images/title-back.jpg`} />
            <div className="container">
              <div className="event-container">{this.renderEvents()}</div>
            </div>
            </div>
          }
          <div className="container-icon">
          {this.props.events.length === 0 && (<img className="no-event-img" src={`${process.env.PUBLIC_URL}/images/event-img.png`}/>)}
          </div>
        </CalenderSectionStyle>
        <Footer />
      </> //
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { events: state.events.events };
};

export default connect(mapStateToProps, { fetchEvents })(Events);