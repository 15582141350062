import React, { useState } from "react";
import { GiShakingHands } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { SiGnuprivacyguard } from "react-icons/si";

import { BenefitSectionStyle } from "./Organisms.style";

const Benefits = () => {
  return (
    <>
      <BenefitSectionStyle>
        <div className="textcard">
          <div className="singlecard">
            <GiShakingHands className="header" />
            <h2>Multiply Your Business</h2>
            <p>
              Since the time of its inception SIA has endeavored to meet the
              various needs of enterprises by combining the power of strategic
              partnerships and bringing together businesses, government
              agencies, service providers & experts.
            </p>
          </div>
          <div className="singlecard">
            <div className="image">
              <HiUserGroup className="header" />
            </div>
            <h2>Membership Benefits</h2>
            <p>
              For any industry or business SIA serves as a single reliable
              point of reference and connects its members with businesses,
              corporate houses, organizations, government agencies, Consulates
              or Embassies.
            </p>
          </div>
          <div className="singlecard">
            <SiGnuprivacyguard className="header" alt="" />
            <h2>Register Now</h2>
            <p>
              You can find more information about the application process click
              here or you can write to info@siapune.com
            </p>
          </div>
        </div>
      </BenefitSectionStyle>
    </>
  );
};

export default Benefits;
