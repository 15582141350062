// categoryReducer.js
import { CATEGORY_LOAD, CATEGORY_LOAD_FAIL, CATEGORY_LOAD_SUCCESS } from "../actions/types";

const initialState = {
  categories: [],
  loading: false,
  error: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LOAD:
      return { ...state, loading: true };
    case CATEGORY_LOAD_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case CATEGORY_LOAD_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default categoryReducer;
