import React, { useState, useEffect, useRef } from "react";
import { ContactSectionStyle } from "./Organisms.style";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { BsFillMapFill, BsMap, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import Axios from "axios";

const RegisterNowSection = () => {
  const [data, setData] = useState({
    company_name: "",
    reference: "",
    reference_number: "",
    gst_no: "",
    pan_no: "",
    udyam_adhar: "",
    contact_no: "",
    email: "",
  });
  // data = formvalue
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const formRef = useRef();

  const handleChange = (event) => {
    // const {name, value}= event.target;
    // setData({...data, [name]: value});
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
    // formRef.current?.reset()   //not working
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(validationform(data));
    setIsSubmit(true);
  };
  const validationform = (value) => {
    const errors = {};
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isphone =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    if (!value.company_name) {
      errors.company_name = "Please enter company name";
    }
    if (!value.reference) {
      errors.reference = "Please enter reference name";
    }
    if (!value.email) {
      errors.email = "Please enter email";
    } else if (!emailPattern.test(value.email)) {
      errors.email = "Enter valid email";
    }
    if (!value.contact_no) {
      errors.contact_no = "Please enter phone";
    } else if (!isphone.test(value.contact_no)) {
      errors.contact_no = "Please enter valid phone number";
    }
    if (!value.reference_number) {
      errors.reference_number = "Please enter phone";
    } else if (!isphone.test(value.reference_number)) {
      errors.reference_number = "Please enter valid phone number";
    }
    if (!value.gst_no) {
      errors.gst_no = "Please enter GST Number";
    }
    if (!value.gst_no) {
      errors.gst_no = "Please enter PAN Number";
    }
    if (!value.udyam_adhar) {
      errors.udyam_adhar = "Please enter Udyam AAdhar Number";
    }
    return errors;
  };
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      Axios.post(`${process.env.REACT_APP_API_URL}/api/form`, {
        company_name: data.company_name,
        reference: data.reference,
        reference_number: data.reference_number,
        gst_no: data.gst_no,
        pan_no: data.pan_no,
        udyam_adhar: data.udyam_adhar,
        contact_no: data.contact_no,
        email: data.email,
      }).then((res) => {
        console.log("submit", res.data);
      });
    }
  }, [formError]);

  return (
    <>
      <ContactSectionStyle>
        <section className="contact_us">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="contact_inner">
                  <div className="contact-row-new">
                    <div className="contact_form_inner-new">
                      <div className="contact_field-new contact-form-fields">
                        <h3>Register To Become A Member</h3>
                        <p>
                          Once we receive the details then it will be reviewed
                          by our team!.
                        </p>
                        {Object.keys(formError).length === 0 && isSubmit ? (
                          <div className="alert">
                            Form Submitted Successfully, We will get back you
                            very soon
                          </div>
                        ) : null}
                        <form onSubmit={(e) => handleSubmit(e)}>
                          <div className="contact-field">
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="Company Name"
                                name="company_name"
                                value={data.company_name}
                                onChange={handleChange}
                              />
                              <span>{formError.company_name}</span>
                            </div>
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="Reference Name"
                                name="reference"
                                value={data.reference}
                                onChange={handleChange}
                              />
                              <span>{formError.reference}</span>
                            </div>
                          </div>
                          <div className="contact-field">
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="Reference Contact Number"
                                name="reference_number"
                                value={data.reference_number}
                                onChange={handleChange}
                              />
                              <span>{formError.reference_number}</span>
                            </div>
                          </div>
                          <div className="contact-field">
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="Email"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                              />
                              <span>{formError.email}</span>
                            </div>
                          </div>
                          <div className="contact-field">
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="Phone Number"
                                name="contact_no"
                                value={data.contact_no}
                                onChange={handleChange}
                              />
                              <span>{formError.contact_no}</span>
                            </div>
                          </div>
                          <div className="contact-field">
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="GST Number"
                                name="gst_no"
                                value={data.gst_no}
                                onChange={handleChange}
                              />
                              <span>{formError.gst_no}</span>
                            </div>
                          </div>
                          <div className="contact-field">
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="PAN Number"
                                name="pan_no"
                                value={data.pan_no}
                                onChange={handleChange}
                              />
                              <span>{formError.pan_no}</span>
                            </div>
                          </div>
                          <div className="contact-field">
                            <div className="forminput">
                              <input
                                type="text"
                                className="form-control form-group"
                                placeholder="Udyam Aadhar Number"
                                name="udyam_adhar"
                                value={data.udyam_adhar}
                                onChange={handleChange}
                              />
                              <span>{formError.udyam_adhar}</span>
                            </div>
                          </div>
                          <button className="contact_form_submit">Send</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContactSectionStyle>
    </>
  );
};

export default RegisterNowSection;
