import {
  PROFILE_LOAD,
  PROFILE_LOAD_SUCCESS,
  PROFILE_LOAD_FAIL,
  SINGLE_PROFILE_LOAD,
  SINGLE_PROFILE_LOAD_SUCCESS,
  SINGLE_PROFILE_LOAD_FAIL,
  REMOVE_PROFILE,
  PROFILE_CREATE,
  PROFILE_CREATE_SUCCESS,
  PROFILE_CREATE_FAIL,
  USER_PROFILE_LOAD,
  REMOVE_USER_PROFILE,
  USER_PROFILE_UPDATING,
  USER_PROFILE_UPDATE_SUCCESSFUL,
  GET_IS_PAID_LOADED,
  SET_IS_PAID,
  IS_LOADING,
  BANEER_LOAD_SUCCESS,
  BANNER_LOAD_FAIL,
  REMOVE_ME,
  CATEGORY_LOAD, 
  CATEGORY_LOAD_SUCCESS, 
  CATEGORY_LOAD_FAIL
} from "./types";
import axios from "axios";
import axiosAPI from "../../api/axios";
import { useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";




// FOR creating the profile needs correction token should be included to prevent unauthorized access or the user should be logged in
// work on it

export const createProfile =
  (
  formData,
  cb
  ) =>
  async (dispatch) => {
  
    const config = {
      headers: {
        Authorization:`Bearer ${localStorage.getItem('access_token')}`,
        "Content-Type": "multipart/form-data"
      },
    };

 

    try {
      const {data,status} = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/createprofile`,
        formData,
        config
      );

      // useEffect(() => {
      //   if(status===201){
      //     cb();
      //  }
      // }, [status]);
          if(status===201){
             cb();
          }
      dispatch({
        type: PROFILE_CREATE_SUCCESS,
        payload: data,
      });

    } catch (err) {
      dispatch({
        type: PROFILE_CREATE_FAIL,
      });
    }
  };




export const listProfile2 = () => async (dispatch) => {

  try {
    dispatch({
      type: PROFILE_LOAD,
    });
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/`);

    dispatch({
      type: PROFILE_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listProfile = (filters = {}) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_LOAD,
    });

    // Destructure the filters object to extract category_field, city, and area
    const { category_field, city, area } = filters;

    // Construct the query parameters string based on the provided filters
    let queryParams = '';

    if (category_field) {
      queryParams += `category_field=${category_field}&`;
    }

    if (city) {
      queryParams += `city=${city}&`;
    }

    if (area) {
      queryParams += `area=${area}&`;
    }

    // Trim any trailing '&' or '?' from the queryParams string
    queryParams = queryParams ? `?${queryParams.slice(0, -1)}` : '';

    // Send the GET request with the query parameters
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/${queryParams}`);

    dispatch({
      type: PROFILE_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



export const fetchProfile = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SINGLE_PROFILE_LOAD,
    });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/detail/${id}`
    );
 
    dispatch({
      type: SINGLE_PROFILE_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_PROFILE_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserProfile=()=>async(dispatch)=>{
  
  try {
    // const navigation = useNavigate();

    dispatch({
      type: PROFILE_LOAD,
    });
    const { data,...value } = await axios.get(`${process.env.REACT_APP_API_URL}/api/yourprofile/`,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('access_token')}`
      }
    });

    console.log(value.status)
   
    dispatch({
      type: USER_PROFILE_LOAD,
      payload: data,
    });
    if(value.status===200&&data!==[]){
      
      console.log("data")
      localStorage.setItem('profile',"CREATED")
          // cb()
    }else{
  localStorage.removeItem("profile");

    }
  } catch (error) {
    dispatch({
      type: PROFILE_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const updateUserProfile=(formData,id,cb)=>async(dispatch)=>{
  try {
    dispatch({
      type: USER_PROFILE_UPDATING,
    });
    const { data ,status} = await axios.patch(`${process.env.REACT_APP_API_URL}/api/update/${id}`,formData,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('access_token')}`,
        "Content-Type": "multipart/form-data"
      }
    });
      if(status===200){

        cb();
      }
    dispatch({
      type: USER_PROFILE_UPDATE_SUCCESSFUL,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: PROFILE_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const getIsPaid=()=>async(dispatch)=>{

  try {
      dispatch({
        type:IS_LOADING,
      })
   const {data,status}=await axios.get(`${process.env.REACT_APP_API_URL}/api/ispaid`,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('access_token')}`,
      }
    })
    console.log("status",data.ispaid)
    if(status===200){
      dispatch({
        type:GET_IS_PAID_LOADED,
        payload:data.ispaid
      })
      }else{
        dispatch({
          type:GET_IS_PAID_LOADED,
          payload:false
        }) 
      }
  } catch (error) {
    dispatch({
      type:GET_IS_PAID_LOADED,
      payload:false
    })
  }
}

export const setIsPaid=(value)=>(dispatch)=>{
  dispatch(
   { type:SET_IS_PAID,
    payload:value}
  )
}
export const removeProfile=()=>(dispatch)=>{
    dispatch({
      type:REMOVE_PROFILE,
      payload:{}
    })
}

export const removeUserProfile=()=>(dispatch)=>{
  
  dispatch(
    {
      type:REMOVE_USER_PROFILE,
      payload:{}
    }
  )
}

export const remove_me=()=>(dispatch)=>{
  
  dispatch(
    {
      type:REMOVE_ME,
      payload:{}
    }
  )
}
// export const fetchEvent = (id) => async (dispatch) => {
//   const response = await axios.get(
//     `${process.env.REACT_APP_API_URL}/api/events/${id}`
//   );

// BANEER_LOAD_SUCCESS,
// BANNER_LOAD_FAIL


export const fetchCategories = () => async (dispatch) => {
  dispatch({ type: CATEGORY_LOAD });

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/category/`);
    dispatch({
      type: CATEGORY_LOAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_LOAD_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};