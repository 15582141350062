import React, { useEffect, useState } from "react";
import { Route, Redirect, Navigate, useNavigate, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import { isAuthenticated } from "../../api/authenticationApi";
import axios from "axios";
// import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

const Forprofile = ({ children, ...rest }) => {
  const isPaid=useSelector(state=>state.profileReducer.isPaid);
  const {isAuthenticated}=useSelector(state=> state.auth)
//   const location = useLocation();
const profile = useSelector((state) => state.profileReducer.me);

 console.log("here1")
   if(!isAuthenticated) return ( <Navigate
    replace to= "/login"
  />);
 console.log("here2")
    
  // if(!localStorage.getItem('isPaid') &&isAuthenticated && !isPaid && location.pathname !=='/payment') return( <Navigate
  //   replace to= "/payment"
  // />);
  console.log("reject profile")

  console.log(isPaid)
  console.log(isAuthenticated)
//   console.log(location.pathname)

  // if(isAuthenticated && isPaid&&location.pathname ==='/profile/create') return( <Navigate
  if(!profile[0]) return( <Navigate
    replace to= "/profile/create"
  />);

 console.log("success profile")

  return children;
};



// PrivateRoute_forprofile.propTypes = {
//   component: PropTypes.func.isRequired,
//   location: PropTypes.object,
// };

export default Forprofile;
