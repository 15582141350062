import axiosAPI, { setNewHeaders } from "./axios";

export async function signUp(email, username, password, password2) {
  const response = await axiosAPI.post("login/api/register", {
    email,
    username,
    password,
    password2,
  });

  localStorage.setItem("user", response.data);
  return response;
}

export async function obtainToken(username, password) {
  const response = await axiosAPI.post("/login/api/token/", {
    username,
    password,
  });
  setNewHeaders(response);
  return response;
}

export async function refreshToken(refresh) {
  const response = await axiosAPI.post("token/refresh/", {
    refresh,
  });
  setNewHeaders(response);
}

// eslint-disable-next-line
export async function logout() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  // TODO: invalidate token on backend
}

export const isAuthenticated = () => {
  const token = localStorage.getItem("access_token");
  return token ? true : false;
};
