import React from 'react'
import MemberDetailSection from '../organisms/MemberDetailSection'


const MemberDetail = () => {
    
  return (
      <>
       <MemberDetailSection/>
       </>
   
  )
}

export default MemberDetail