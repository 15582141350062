import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ message, onClose }) => {
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        if (typeof onClose === 'function') {
          onClose();
        }
      }, 5000); // 2 seconds

      return () => clearTimeout(timer); // Cleanup on unmount or message change
    }
  }, [message, onClose]);

  if (!message) return null; // Render nothing if there's no message

  return (
    <div role="alert" style={styles.errorContainer}>
      <span style={styles.errorText}>{message}</span>
      <button 
        onClick={onClose} 
        style={styles.closeButton} 
        aria-label="Close error message"
      >
        X
      </button>
    </div>
  );
};

const styles = {
  errorContainer: {
    backgroundColor: '#f44336', // Changed to a slightly different shade of red
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Added shadow for better visibility
  },
  errorText: {
    marginRight: '10px',
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
    padding: '0', // Removed padding to make button more compact
  },
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired, // onClose is required
};

export default ErrorMessage;
