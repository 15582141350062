import React, { useEffect } from "react";
import CalenderSection from "../organisms/CalenderSection";
import Footer from "../organisms/Footer";
import HeroSection from "../organisms/HeroSection";
import MemberSection from "../organisms/MemberSection";
import Navbar from "../organisms/Navbar";
import Contact from "./Contact";
import { connect } from "react-redux";
import { checkAuthenticated, load_user } from "../../redux/actions/auth";
import Benefits from "../organisms/Benefits";
import Slider from "../organisms/Slider";
import TestProfileList from "../organisms/TestProfileList";
import ContactSection from "../organisms/ContactSection";
// import TestProfileList from "../organisms/TestProfileList";

const LandingPage = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  console.log(today);

  return (
    <>
      <Navbar />
      <Slider />
      <Benefits />
      <TestProfileList />
      {/*<MemberSection /> */}
      <CalenderSection today={today} />
      <ContactSection />
      <Footer />
    </>
  );
};

export default LandingPage;
