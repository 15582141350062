import {
  BANEER_LOAD_SUCCESS,
  BANNER_LOAD_FAIL,
} from "../actions/types";

const initialState = {
  items: [],
  loading: true,
  error: null
};

export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANEER_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data
      }
    case BANNER_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      }
    default:
      return state;
  }
};
