import React from "react";
import { TitleSectionThreeStyle } from "./Molecule.Style";

const TitleSectionThree = (props) => {
  return (
    <>
      <TitleSectionThreeStyle>
        <section className="showcase">
          <img src={props.imgUrl} alt="Picture" />
          <div className="overlay">
            <h2>{props.title}</h2>
            <p>{props.para}</p>
          </div>
        </section>
      </TitleSectionThreeStyle>
    </>
  );
};

export default TitleSectionThree;
