import React from 'react'
import CertificateSection from '../organisms/CertificateSection'
import Footer from '../organisms/Footer'

import Navbar from '../organisms/Navbar'


const Certificate = () => {
    
  return (
      <>
      {/* <Navbar /> */}
       <CertificateSection/>
       {/* <Footer /> */}

       </>
   
  )
}

export default Certificate