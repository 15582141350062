import React, { useEffect, useState } from "react";
import { listProfile } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { MemberSectionStyle } from "./Organisms.style";
import Alert from "../atoms/Alert";
import { Link } from "react-router-dom";

class MemberSection extends React.Component {
  componentDidMount() {
    this.props.listProfile();
  }

  renderProfiles() {
    return this.props.profiles.map((profile) => {
      return (
        <div className="card" key={profile.id}>
          <img className="card-header" src={profile.profile_image} alt="" />
          <div className="card-body">
            <h3>{profile.company_name}</h3>
            <p>{profile.company_details}</p>
            <p>{profile.address}</p>

            <Link to={`/profile/${profile.id}`} className="btn">
              Read more
            </Link>
          </div>
        </div>
      );
    });
  }

  render() {
    console.log(this.props.profiles);
    return (
      <>
        <MemberSectionStyle>
          <div className="container" >
            <div className="heading">
              <h1>Members</h1>
            </div>
            <div className="row">{this.renderProfiles()}</div>
          </div>
        </MemberSectionStyle>
      </> //
    );
  }
}

const mapStateToProps = (state) => {
  return { profiles: state.profileReducer.profiles };
};

export default connect(mapStateToProps, { listProfile })(MemberSection);
