import {
  SERVICE_PROVIDER_LOAD,
  SERVICE_PROVIDER_LOAD_SUCCESS,
  SERVICE_PROVIDER_LOAD_FAIL,
  USER_SERVICE_PROVIDER_LOAD,
  SINGLE_SERVICE_PROVIDER_LOAD,
  SINGLE_SERVICE_PROVIDER_LOAD_SUCCESS,
  SINGLE_SERVICE_PROVIDER_LOAD_FAIL,
  REMOVE_SERVICE_PROVIDER,
  SERVICE_PROVIDER_CREATE,
  SERVICE_PROVIDER_CREATE_FAIL,
  SERVICE_PROVIDER_CREATE_SUCCESS,
  SERVICE_PROVIDER_UPDATING,
} from "./types";
import axios from "axios";


export const listServiceProvider = (filters = {}) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_PROVIDER_LOAD,
    });

    // Destructure the filters object to extract category_field, city, and area
    const { service_type, city, area } = filters;

    // Construct the query parameters string based on the provided filters
    let queryParams = '';

    if (service_type) {
      queryParams += `service_type=${service_type}&`;
    }

    if (city) {
      queryParams += `city=${city}&`;
    }

    if (area) {
      queryParams += `area=${area}&`;
    }

    // Trim any trailing '&' or '?' from the queryParams string
    queryParams = queryParams ? `?${queryParams.slice(0, -1)}` : '';

    // Send the GET request with the query parameters
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/service_provider/${queryParams}`);

    dispatch({
      type: SERVICE_PROVIDER_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_PROVIDER_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const fetchServiceProviderProfile = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SINGLE_SERVICE_PROVIDER_LOAD,
    });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/service_detail/${id}`
    );

    dispatch({
      type: SINGLE_SERVICE_PROVIDER_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_SERVICE_PROVIDER_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const getUserServiceProvider = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/yourserviceprofile`);

    dispatch({
      type: USER_SERVICE_PROVIDER_LOAD,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_PROVIDER_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

