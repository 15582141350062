import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  LOGOUT,
  PROFILE_LOAD,
  PROFILE_LOAD_SUCCESS,
  PROFILE_LOAD_FAIL,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
} from "./types";
import axios from "axios";
import { getIsPaid, getUserProfile, removeUserProfile, remove_me } from "./listProfile";

export const signup = (username, email, password, password2) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ username, email, password, password2 });

    try {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_URL}/login/api/register`,
        body,
        config
      );
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: data,
      });
      if (status === 200) {
        dispatch(getUserProfile());
        dispatch(getIsPaid());
      }
      dispatch(load_user());
    } catch (err) {
      dispatch({
        type: SIGNUP_FAIL,
        payload: err.response.data,
      });
    }
  };

export const login = (username, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ username, password });

  try {
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_API_URL}/login/api/token/`,
      body,
      config
    );
    localStorage.setItem("access_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
    if (status === 200) {
      dispatch(load_user())
      dispatch(getUserProfile());
      dispatch(getIsPaid());
    }
    dispatch(load_user());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};


export const checkAuthenticated = () => async (dispatch) => {
  console.log("checkAuthenticated");
  if (localStorage.getItem("access_token")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const body = JSON.stringify({
      token: localStorage.getItem("access_token"),
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/login/api/token/verify/`,
        body,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("username");
        localStorage.removeItem("isPaid");

        dispatch({
          type: LOGIN_FAIL,
        });
      }
    } catch (err) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("isPaid");
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  } else {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isPaid");
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};


export const listProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_LOAD,
    });
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/`);
    dispatch({
      type: PROFILE_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_LOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


//  load the user  temporarly loading profile
export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access_token")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        Accept: "application/json",
      },
    };

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/showdata`,
        config
      );
      localStorage.setItem("username", data.username);

      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/login/resetpass`,
      body,
      config
    );
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
      response,
    });
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
      error,
    });
  }
};

export const reset_password_confirm =
  (uid, token, password, password2) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ uid, token, password, password2 });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/login/resetpass/${uid}/${token}`,
        body,
        config
      );
      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("username");
  localStorage.removeItem("isPaid");
  // dispatch
 dispatch(remove_me());
  dispatch({
    type: LOGOUT,
  });
};
