import { LOAD_EVENTS, LOAD_EVENT, LOAD_EVENTS_SUCCESS } from "./types";
import axios from "axios";

//
export const fetchEvents = () => async (dispatch) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/events/`
  );

  dispatch({ type: LOAD_EVENTS, payload: response.data });
};

export const fetchEvent = (id) => async (dispatch) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/eventsdetail/${id}`
  );

  dispatch({ type: LOAD_EVENT, payload: response.data });
};

// export const fetchEvent = (id) => async (dispatch) => {
//   const response = await axios.get(
//     `${process.env.REACT_APP_API_URL}/api/events/${id}`
//   );

//   dispatch({ type: LOAD_EVENT, payload: response.data });
// };

// export const fetchEvent = (event) => {
//   return {
//     type: LOAD_EVENTS,
//    payload: event,
//   }
// }
