import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  LOGOUT,
  PROFILE_LOAD,
  PROFILE_LOAD_SUCCESS,
  PROFILE_LOAD_FAIL,
  SIGNUP_SUCCESS,
  PASSWORD_RESET_FAIL,
  SIGNUP_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
} from "../actions/types";

const initialState = {
  access: localStorage.getItem("access_token"),
  refresh: localStorage.getItem("refresh_token"),
  isAuthenticated: null,
  isLoading: true,
  isLoggedIn: false,
  user: {

  },
  message:undefined,
  error: null,
};




// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };
    case LOGIN_SUCCESS :
      return {
        ...state,
        isLoggedIn: true,
        isAuthenticated: true,
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
        refresh: payload.refresh,
        message:undefined
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        error: action.payload, // Store error in state
        isAuthenticated: false,
        loading: false,
      };
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case AUTHENTICATED_FAIL:
      
      return {
        ...state,
        isAuthenticated: false,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
      };
    case LOGIN_FAIL:
     return {
      ...state,
      access: null,
      refresh: null,
      isAuthenticated: false,
      user: null,
      message:'Incorrect Password or username'
    }
    case LOGOUT:
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: {},
      
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        message:'Password reset link sent to email'
      }
    case PASSWORD_RESET_FAIL:
      return {
        ...state,
        message:'Incorrect Email'
      }
    case PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        ...state,
        message:'Password reset successful'
      }
    case PASSWORD_RESET_CONFIRM_FAIL:
      
      return {
        ...state
      }
  
    default:
      return state;
  }
}
