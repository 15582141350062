import React, { useState } from "react";
import { DropdownSelectStyle } from "./Molecule.Style";

const DrpdownSelectTwo = ({  location, data, onItemClick }) => {
  const [ selectedl, setSelectedl ] = useState("")
  const [isActive, setIsActive] = useState(false);
  console.log("active", selectedl);
  return (
    <>
      <DropdownSelectStyle>
        <div className="dropdown" onClick={(e) => e.stopPropagation()}>
          <div
            className="dropdown-btn"
            onClick={(e) => {
              setIsActive(!isActive);
            }}>
            {selectedl ? selectedl : <p>City</p>}
            <span className="fas fa-caret-down"></span>
          </div>
          {isActive && (
            <div className="dropdown-content">
              {location.map((item, index) => (
                <div
                  onClick={(e) => {
                    setSelectedl(item);
                    onItemClick(item);
                    setIsActive(false);
                  }}
                  key={index}
                  className="dropdown-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      </DropdownSelectStyle>
    </>
  );
};

export default DrpdownSelectTwo;
