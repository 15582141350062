import React from 'react'
import Footer from '../organisms/Footer'
import Navbar from '../organisms/Navbar'
import BlogSection from '../organisms/BlogSection'


const Login = () => {
    
  return (
      <>
      <Navbar/>
       <BlogSection/>
       <Footer/>
       </>
   
  )
}

export default Login