import React from 'react'
import Footer from '../organisms/Footer'
import MemberSignup from '../organisms/MemberSignup'
import Navbar from '../organisms/Navbar'


const Member = () => {
  return (
      <>
      <Navbar />
       <MemberSignup/>
       <Footer/>
       </>
   
  )
}

export default Member