import React from 'react'
import Footer from '../organisms/Footer'
import Navbar from '../organisms/Navbar'
import RegisterNowSection from '../organisms/RegisterNowSection'

const RegisterNow = () => {
  return (
    <>
    <Navbar />
    <div>
    <RegisterNowSection />
    </div>
    <Footer/>
    </>
  )
}

export default RegisterNow