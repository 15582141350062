import { useState, CSSProperties } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/auth";
import { LoginSectionStyle } from "./Organisms.style";
import { isAuthenticated } from "../../api/authenticationApi";
import Loader from "../molecules/Loader";
import { useEffect } from "react";

const LoginSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // console.log(dispatch);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingError, setLoadingError] = useState(null);

  const { access, message, isAuthenticated, user, isLoading } = useSelector(
    (state) => state.auth
  );
  // const errorMessage = useSelector(state=> state.auth.)
  const initialFormData = Object.freeze({
    username: "",
    password: "",
  });

  const [formData, setFormData] = useState(initialFormData);

  const { username, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    setLoadingLogin(true);
    e.preventDefault();
    dispatch(login(username, password))
      .then(() => {
        setLoadingLogin(false);
        navigate ("/profile")
      })
      .catch((err) => {
        setLoadingError(message);
      });
  };

  // Is the user authenticated?
  // Redirect them to homepage
  if (isAuthenticated && user) {
    return <Navigate replace to="/profile" />;
  }
  if (loadingLogin) {
    return <Loader />;
  }

  return (
    <>
      <LoginSectionStyle>
        <div className="container">
          <div className="forms">
            <div className="form login">
              <span className="title">Login</span>
              <br />
              {message ? <span>{message}</span> : ""}
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="input-field">
                  <input
                    type="text"
                    placeholder="Enter username"
                    name="username"
                    required
                    value={username}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="input-field">
                  <input
                    type="password"
                    className="password"
                    name="password"
                    placeholder="Enter your password"
                    required
                    value={password}
                    onChange={(e) => onChange(e)}
                    minLength="6"
                  />

                  <i className="uil uil-eye-slash showHidePw"></i>
                </div>

                <div className="checkbox-text">
                  <div className="checkbox-content"></div>
                </div>

                <div className="input-field button">
                  <input type="submit" value="Login Now" />
                </div>
              </form>
              <p>
                Want to become a Association member?
                <Link to="/enquire"> Register</Link>
              </p>
              <p>
                Forgot your password?
                <Link to="/reset-password"> Reset Password</Link>
              </p>
            </div>
          </div>
        </div>
      </LoginSectionStyle>
    </> //
  );
};

export default LoginSection;
