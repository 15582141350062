import React from "react";
import { Link } from "react-router-dom";

function SliderContent({ activeIndex, imageSlider, onClick }) {

  return (
    <>
      <section>
        {imageSlider.map((slide, index) => (
          <div
            key={index}
            className={index === activeIndex ? "slides active" : "inactive"}>
            <img className="slide-image" src={slide.url} alt="" />
          </div>
        ))}
      </section>
    </>
  );
}

export default SliderContent;
