import {
    BANEER_LOAD_SUCCESS,
    BANNER_LOAD_FAIL
  } from "./types";
  import axios from "axios";


export const load_banner = () => async (dispatch) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      }
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/images`,
          config
        );
        dispatch({
          type: BANEER_LOAD_SUCCESS,
          payload: {data},
        });
      } catch (error) {
        dispatch({
          type: BANNER_LOAD_FAIL,
          payload: {error}
        });
      }
    
  };
  