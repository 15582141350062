import React from "react";
import styled from "styled-components";
import { MdAccessTimeFilled, MdOutlineDateRange } from "react-icons/md";
import { GoLocation, GoCalendar } from "react-icons/go";
import { GrFormNext } from "react-icons/gr";
import { AiOutlineFieldTime } from "react-icons/ai";
import {
  BsCalendar2Date,
  BsClipboardCheck,
  BsCalendar2Event,
} from "react-icons/bs";
import { Link } from "react-router-dom";

const EventCard = ({data}) => {
  return (
    <EventCardStyle>
      
      {
        <Link to={`/events/${data.id}`}>
        <div className="event">
          <div className="event-left">
            <div className="event-date">
              <div className="month">{data.start_day}</div>
            </div>
          </div>
          <div className="event-right">
            <h3 className="event-title">{data.title}</h3>
            <div className="event-description">
              <BsClipboardCheck className="agenda-icon" /> {data.agenda}
            </div>
            <div className="event-timing">
              <MdAccessTimeFilled className="event-time-icon" /> {data.start_time}
            </div>
          </div>
        </div>
        </Link>
     }       
     
    </EventCardStyle>
  );
};

export default EventCard;

const EventCardStyle = styled.div`

`;
