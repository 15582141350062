import React from "react";
import Privacy from "../organisms/Privacy";
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Privacy />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
