import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { listProfile } from "../../redux/actions/listProfile";
import Alert from "../atoms/Alert";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BiRightArrow } from "react-icons/bi";
import { TestProfileListStyle } from "./Organisms.style";
import SectionHeading from "../atoms/SectionHeading";
import CtaButton from "../atoms/CtaButton";
import ProfileCard from "../molecules/ProfileCard";

const TestProfileList = () => {
  const [limit, setLimit] = useState(8);
  const { profiles } = useSelector((state) => ({
    profiles: state.profileReducer.profiles,
  }));

  const navigate = useNavigate();
  // const handleClick =(event) =>{
  //   event.preventDefault();
  //   alert('you clicked me');
  //   navigate('/members');
  // }


  const dispatch = useDispatch();
  console.log(profiles);

  const fetchProfile = async () => {
    dispatch(listProfile());
  };

  useEffect(() => {
    if (profiles) {
      fetchProfile();
    }
  }, []);

  // const { loading, error, profiles } = profileList;
  return (
    <>
      <TestProfileListStyle>
        <div className="container">
          {profiles ? (
            <div>
              <SectionHeading heading="Our Members" />
              <div className="body">
                {profiles
                  .slice(0, limit ? limit : profiles.length)
                  .map((profile, id) => {
                    return <ProfileCard profile={profile} key={id} />;
                  })}
              </div>
            </div>
          ) : (
            <p>Loading</p>
          )}
          <div className="members-pg">
            <CtaButton
              type="button"
              buttonStyle="btn--primary--solid"
              buttonSize="btn--medium"
             ><Link to='/members'>
             Load More
             </Link>
            </CtaButton>
          </div>
        </div>
      </TestProfileListStyle>
    </>
  );
};

export default TestProfileList;
