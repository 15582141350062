import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BlogSctionStyle } from "./Organisms.style";
import ClipLoader from "react-spinners/ClipLoader";

const BlogSection = () => {
  const [blog, setBlog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("blog", blog);

  const fetchBlog = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/blogs`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBlog(result);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  useEffect(() => {
    fetchBlog();
  }, []);
  return (
    <>
      <BlogSctionStyle>
        <div className="blog-section">
          <div className="container">
          
            {error && <div className="error">{error}</div>}
            <div className="loading-loader">{isLoading && (
              <ClipLoader size={30} color={"#0099d9"} loading={isLoading} />
            )}</div>
            
            {blog &&
              blog.length > 0 &&
              blog.map((blogData, id) => (
                <div className="card" key={id}>
                  <div className="image">
                    <img className="header" src={blogData.thumbnail} alt="" />
                  </div>
                  <div className="text">
                    <h1>{blogData.title}</h1>
                    <p>
                      {blogData.date_created} {blogData.month}
                    </p>
                    <h4>{blogData.content}</h4>
                    <div className="button">
                      <Link to={`/blog/${blogData.id}`}>Read More</Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </BlogSctionStyle>
    </>
  );
};

export default BlogSection;
