import React from "react";
import { SectionHeadingStyle } from "./Atom.Style";

const SectionHeading = (props) => {
  return (
    <>
      <SectionHeadingStyle>
        <div className="heading-container">
          <h1>
            {props.heading}
            <span></span>
          </h1>
        </div>
      </SectionHeadingStyle>
    </>
  );
};

export default SectionHeading;
