import React from "react";
import { TitleSectionMembersStyle } from "./Molecule.Style";

const TitleSectionMembers = (props) => {
  return (
    <>
      <TitleSectionMembersStyle>
        <div className="overlay"></div>
        <div className="background-overlay">
          <div className="image">
            <img className="card-header" src={props.img} alt="" />

            <div className="text">
              <h2>
                {/*this.props.event.location */}
                <br />
                {props.name} <br />
                {props.subtitle}
              </h2>
            </div>
          </div>
        </div>
      </TitleSectionMembersStyle>
    </>
  );
};

export default TitleSectionMembers;
