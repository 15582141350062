import React, {useState} from "react";
import {reset_password_confirm} from "../../redux/actions/auth"
import { Link, Navigate, useParams } from "react-router-dom";
import { connect,useDispatch,useSelector } from "react-redux";
import { LoginSectionStyle } from "../organisms/Organisms.style";
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";

const ResetPasswordConfirm = () => {
  const [requestSent, setRequestSent] = useState(false)
  const params = useParams()

  const [formData, setFormData] = useState({
    password: "", password2: ""
  });

  const dispatch= useDispatch();
  const errorMessage = useSelector(state=> state.auth.message)
  

  const { password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    const uid = params.uid;
    const token = params.token;
    dispatch(reset_password_confirm(uid, token, password, password2));   
    setRequestSent(true)
  };
  return (
    <>
    <Navbar />
    <LoginSectionStyle>
    <div className="container">
          <div className="forms">
            <div className="form login">
              <span className="title">Reset Password</span>
              <br/>
              <p>{errorMessage?<span>{errorMessage}</span>:''}</p> 
              <form onSubmit={(e) => onSubmit(e)}>
              <div className="input-field">
                  <input
                    type="password"
                    className="password"
                    name="password"
                    placeholder="Enter New password"
                    required
                    value={password}
                    onChange={(e) => onChange(e)}
                    minLength='6'
                  />

                  <i className="uil uil-eye-slash showHidePw"></i>
                </div>
                <div className="input-field">
                  <input
                    type="password"
                    className="password"
                    name="password2"
                    placeholder="Confirm New password"
                    required
                    value={password2}
                    onChange={(e) => onChange(e)}
                    minLength='6'
                  />

                  <i className="uil uil-eye-slash showHidePw"></i>
                </div>

                <div className="checkbox-text">
                  <div className="checkbox-content"></div>
                </div>

                <div className="input-field button">
                  <input type="submit" value="Reset Password" />
                </div>
              </form>
              
            </div>
          </div>
        </div>
    </LoginSectionStyle>
    <Footer/>
    </>
  );
};

export default connect(null, {reset_password_confirm}) (ResetPasswordConfirm);
