import React, { useEffect, useState } from "react";
import Arrows from "./Arrows";
import Dots from "./Dots";
import SliderContent from "./SliderContent";
import { SliderStyle } from "../../organisms/Organisms.style";



function Slider({imageSlider}) {
  const len = imageSlider.length - 1;
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 6000);
    return () => clearInterval(interval);
  }, [activeIndex]);
  return (
    <>
      <SliderStyle>
        <div className="slider-container">
          <SliderContent activeIndex={activeIndex} imageSlider={imageSlider} />
          <Arrows
            prevSlide={() =>
              setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
            }
            nextSlide={() =>
              setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
            }
          />
          <Dots
            activeIndex={activeIndex}
            imageSlider={imageSlider}
            onClick={(activeIndex) => setActiveIndex(activeIndex)}
          />
        </div>
      </SliderStyle>
    </>
  );
}

export default Slider;
