import React, { useEffect, useState, useRef, useMemo } from "react";
import { CreateProfileStyle } from "./Page.style";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getUserProfile,
  removeUserProfile,
  updateUserProfile,
  createProfile,
} from "../../redux/actions/listProfile";
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import JoditEditor from "jodit-react";
import Loader from "../molecules/Loader";
import { fetchCategories } from "../../redux/actions/listProfile";
import { StyledSelect } from "../organisms/Organisms.style";


const CreateProfile = () => {
  const editor = useRef(null)
  const dispatch = useDispatch();
  const navigation = useNavigate();
  // const [value, setValue] = useState(myProfile?.company_details || "");

  const categories = useSelector((state) => state.categoryReducer.categories);

  const profile = useSelector((state) => state.profileReducer.me);
  const dontknow = useSelector((state) => state.profileReducer.loading);
  const is = useSelector((state) => state.updateUserProfile);
  console.log("dont", is)
  const [isValid, setIsvalid] = useState(false);
  const [isCreateProfile, setIsCreateProfile] = useState(true);
  const location = useLocation();
  const [myProfile, setMyProfile] = useState({
    name: "",
    company_name: "",
    company_details: "",
    address: "",
    contact: "",
    company_email: "",
    category_field: "",
    profile_image: "",
    city: "",
    area: "",
    pincode: "",
  });
  const for_edit = (data) => {
    setMyProfile({ ...myProfile, company_details: data })
  }
  const [content, setContent] = useState(myProfile.company_details);

  useEffect(() => {
    dispatch(getUserProfile(() => navigation("/profile")));
    dispatch(fetchCategories());
    // return () => {
    //   dispatch(removeUserProfile());
    // };
  }, []);
  useEffect(() => {
    if (profile.length) {
      setIsCreateProfile(false);
      setMyProfile(profile[0]);
    }
  }, [profile]);
  const [u_id, setu_id] = useState();
  useEffect(() => {
    let isValid = true;
    console.log(myProfile)
    if (myProfile?.id) {
      setContent(myProfile?.company_details)
      setu_id(myProfile?.id)
    }
    if (myProfile?.id && location.pathname.includes("create")) {
      // setu_id(myProfile?.id)
      navigation('/profile')
    }

    for (const key in myProfile) {
      if (myProfile[key] === "") {
        isValid = true;
        break;
      }
    }
    setIsvalid(isValid);
    console.log(isValid)



  }, [myProfile]);

  const handleChange = (event) => {
    if (event.target.name === "profile_image") {
      setMyProfile({
        ...myProfile,
        [event.target.name]: event.target.files[0],
      });
    } else {
      // Ensure category_field is set as a string
      const value = event.target.name === "category_field" ? event.target.value : event.target.value;
      setMyProfile({ ...myProfile, [event.target.name]: value });
    }
  };
  useEffect(() => {
    if (isCreateProfile) {
      // navigation("/profile")
      console.log(isCreateProfile)
    }
  }, [isCreateProfile]);

  const [c_load, setC_load] = useState(false);
  const handleSubmit = (e) => {
    setC_load(true)
    setTimeout(() => {
      setC_load(false)

    }, 5000);
    e.preventDefault();
    e.stopPropagation();
    console.log("submit");
    if (isValid) {
      const newProfileData = { ...myProfile };
      let id = "";
      if (!isCreateProfile) {
        id = newProfileData.id;
        delete newProfileData.id;
        for (const key in newProfileData) {
          if (profile[0][key] === newProfileData[key]) {
            delete newProfileData[key];
          }
        }
      }
      console.log(content)
      const formData = new FormData();
      for (const key in newProfileData) {
        formData.append(key, newProfileData[key]);
      }
      if (isCreateProfile) {
        dispatch(createProfile(formData, () => navigation("/profile")));
      } else {
        dispatch(updateUserProfile(formData, u_id, () => navigation("/profile")));
      }
    } else {
      setC_load(false)
      alert("fill all the fileds");
    }
  };
  return (
    <>
      <Navbar />

      {dontknow && !myProfile.name ? <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loader />
      </div>
        :

        <CreateProfileStyle>
          <div className="container">
            <div className="forms">
              <div className="form login">
                <span className="title">
                  {location.pathname.includes("update") ? "Update" : "Create"}
                  Your Company Profile
                </span>

                <form>
                  <div className="input-group_fields">
                    <div>
                      <div className="input-field">
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          name="name"
                          value={myProfile.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <input
                          type="text"
                          placeholder="Enter Your Company Name"
                          name="company_name"
                          value={myProfile.company_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <input
                          type="text"
                          placeholder="Address of Company"
                          name="address"
                          value={myProfile.address}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <input
                          type="text"
                          placeholder="Contact Number"
                          name="contact"
                          value={myProfile.contact}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <input
                          type="text"
                          placeholder="Area"
                          name="area"
                          value={myProfile.area}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <input
                          type="text"
                          placeholder="City"
                          name="city"
                          value={myProfile.city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <input
                          type="text"
                          placeholder="Pin Code"
                          name="pincode"
                          value={myProfile.pincode}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <StyledSelect
                          name="category_field"
                          value={myProfile.category_field}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option key={category.id} value={category.category}>
                              {category.category}
                            </option>
                          ))}
                        </StyledSelect>
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>

                      <JoditEditor
                        ref={editor}
                        value={content}
                        onChange={for_edit}


                      />
                    </div>
                    {/* <textarea
                    id="story"
                    name="company_details"
                    rows="5"
                    cols="33"
                    onChange={handleChange}
                    value={myProfile.company_details}
                    placeholder="About Company"
                  ></textarea> */}
                  </div>
                  <div className="checkbox-text">
                    <div className="checkbox-content"></div>
                    <label htmlFor="myfile">Select a Profile Pic:</label>
                    {location.pathname.includes("update") ? (
                      <img
                        className="profile-picture"
                        src={myProfile.profile_image}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      id="myfile"
                      name="profile_image"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-field button">
                    {c_load ? <p>Loading</p> :

                      <input
                        type="submit"
                        value="Submit"
                        // disabled={!isValid}

                        onClick={handleSubmit}
                      />
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </CreateProfileStyle>
      }
      <Footer />
    </>
  );
};

export default CreateProfile;
