import { applyMiddleware, legacy_createStore as createStore, compose } from "redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
// import { configureStore } from "@reduxjs/toolkit";




const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore( reducers, initialState, composeEnhancers(applyMiddleware(reduxThunk)));



export default store