import React from "react";

const ForNotification = () => {
  const array = [
    {
      userId: 1,
      id: 1,
      title:
        "sunt aut facere repellat provident occaecati excepturi optio reprehenderit",
      body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
      isChecked: true,
    },
    {
      userId: 1,
      id: 2,
      title: "qui est esse",
      body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
      isChecked: false,
    },
    {
      userId: 1,
      id: 3,
      title: "ea molestias quasi exercitationem repellat qui ipsa sit aut",
      body: "et iusto sed quo iure\nvoluptatem occaecati omnis eligendi aut ad\nvoluptatem doloribus vel accusantium quis pariatur\nmolestiae porro eius odio et labore et velit aut",
      isChecked: false,
    },
    {
      userId: 1,
      id: 4,
      title: "eum et est occaecati",
      body: "ullam et saepe reiciendis voluptatem adipisci\nsit amet autem assumenda provident rerum culpa\nquis hic commodi nesciunt rem tenetur doloremque ipsam iure\nquis sunt voluptatem rerum illo velit",
      isChecked: false,
    },
    {
      userId: 1,
      id: 5,
      title: "nesciunt quas odio ambaa",
      body: "repudiandae veniam quaerat sunt sed\nalias aut fugiat sit autem sed est\nvoluptatem omnis possimus esse voluptatibus quis\nest aut tenetur dolor neque",
      isChecked: true,
    },
  ];

  console.log("propsarr", array);
  const newArray = array.slice(-1)[0];
  // const newArray = array.slice([0].isChecked: true);
  console.log("new", newArray.title);
  const filArray = array.includes((i) => i.isChecked === false);
  console.log("fill", filArray);
  const ari = Object.values(array).some((k) => !k.isChecked);
  console.log("ari", ari);
  const alert = array.some((k) => !k.isChecked);
  console.log("alert", alert);
  return (
    <div style={{height: "100vh"}}>
      {array &&
        array.map((item) => {
          return (
            <div key={item.id}>
              {item.isChecked ? (
                <div
                  style={{
                    color: "green",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: ".2rem",
                    paddingTop: "5rem",
                    margin: "0 5rem"
                  }}>
                  <h3>{item.title}</h3>
                  <p>{item.body}</p>
                </div>
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

export default ForNotification;


