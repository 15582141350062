import React from "react";
import ReactDom from "react-dom";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { CertificateSectionStyle } from "../organisms/Organisms.style";
import champanerkar from '../../assets/champanerkar.png';
import pallavijadhav from '../../assets/pallavi-jadhav.png';
import sachindandekar from '../../assets/s-dandekar.png'
import supriyajagdale from '../../assets/s-jagdale.png'


const Modal = (props) => {
  const navigate = useNavigate();
  console.log("actions", props.actions)
  console.log("modal props: ", props);
  const certNo = props.userProfile.map((item)=> item.id)
  console.log(certNo)
  const data = props.certificateData || {};
  const associationName = "Sinhgad Industrial Association";
  const title = "Certificate of Membership";
  const name = data["name"] || "";
  const company_name = data["company name"] || "";
  console.log("cert", name);
  console.log("cert", company_name);
  return ReactDom.createPortal(
    <>
      <div
        onClick={() => navigate("/profile")}
        className="ui dimmer modals visible active">
        <div
          onClick={(e) => e.stopPropagation()}
          className="ui standard modal visible active container" style={{width: '940px'}}>
          <i onClick={props.onDismiss} className="close icon"></i>

          <CertificateSectionStyle>
            <div className="container cet-container">
              <div className="certificate-container">
                <div className="certificate">
                  <div className="water-mark-overlay"></div>
                  <div className="certificate-header">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sia-logo.png`}
                      className="logo"
                      alt=""
                    />
                  </div>
                  <div className="certificate-body">
                    <Association className="certificate-title">
                     {associationName}
                    </Association>
                    <h1>{title}</h1>
                    <h3 className="certificate-no" >Cert No.{certNo}</h3>

                    <div className="certificate-content">
                      <div className="about-certificate">
                        <p></p>
                      </div>
                      <p className="topic-title"></p>
                      <Bodytext >
                        <p className="topic-description text-muted">
                          <strong>M/S. {company_name.toUpperCase()} </strong> HAS BEEN
                          ACCEPTED AS A MEMBER OF <br />
                          <strong>
                            SINHGAD INDUSTRIAL ASSOCIATION, PUNE (SIA PUNE)
                          </strong>
                          <br /> and is entitled to all benefits and privileges
                          pertaining to association.
                        </p>
                      </Bodytext>
                    </div>
                    <div className="certificate-footer text-muted">
                      <div className="accr-row">
                        <div className="accr">
                          <div className="accr-row-sec">
                            <div className="accr-row-sec-names">
                            <img src={pallavijadhav} alt="" />
                              <Mainp>Pallavi Jadhav</Mainp>
                              <p>President</p>
                            </div>
                            <div className="accr-row-sec-names">
                            <img src={champanerkar} alt="champanerkar" />
                              <Mainp>Jitendra Champanerkar </Mainp> <p>Vice-President </p>
                            </div>
                            <div className="accr-row-sec-names">
                            <img src={supriyajagdale} alt="" />
                              <Mainp>Supriya Jagdale </Mainp>
                              <p>Secretary</p>
                            </div>
                           
                            <div className="accr-row-sec-names">
                            <img src={sachindandekar} alt="sachindandekar" />
                              <Mainp>Sachin Dandekar</Mainp>
                              <p>Treasurer</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button print-btn" >{props.actions}</div>
              </div>
            </div>
          </CertificateSectionStyle>
        </div>
      </div>
    </>,
    document.querySelector("#modal")
  );
};

export default Modal;


const Association = styled.div`
  font-size:1.5rem;
  font-weight:500;
`;
const Bodytext = styled.div`
  margin-top:3rem;
`;
const Mainp = styled.div`
  font-size: 1.2rem;
  font-weight:500;
`;

const modalContainer = styled.div`
  max-width:600px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateY(-50%, 50%);
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
`
;