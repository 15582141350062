import React from 'react'
import Navbar from '../organisms/Navbar'
import Footer from '../organisms/Footer'
import styled from 'styled-components'

const Disclaimer = () => {
  return (
    <>
      <Navbar />
      <Style>
        <h3 className="disclaimer-heading">Disclaimer</h3>
        <p>
          Please read this disclaimer carefully before using our website or
          becoming a member of our industrial association.
        </p>
        <p>
          <strong>Membership Eligibility:</strong> The industrial association
          membership is open to individuals and businesses that meet the
          eligibility criteria set forth by the association. While we strive to
          ensure the accuracy and completeness of the information provided on
          our website, we do not guarantee that all members will meet the
          eligibility requirements at all times. We reserve the right to refuse
          or revoke membership if eligibility criteria are not met or if any
          misrepresentation is detected.
        </p>
        <p>
          <strong>Membership Benefits: </strong>The benefits and privileges
          associated with membership are subject to change without prior notice.
          We endeavor to provide accurate and up-to-date information about
          membership benefits, but we cannot guarantee the availability of
          specific benefits at all times. Any decisions or actions taken based
          on the information provided on our website are solely at the member's
          discretion and risk.
        </p>
        <p>
          <strong>External Links: </strong>Our website may contain links to
          third-party websites that are not under our control. We provide these
          links for convenience and informational purposes only. The inclusion
          of any external link does not imply endorsement or guarantee of the
          content, products, or services offered on those sites. We are not
          responsible for the accuracy, legality, or safety of the content on
          external websites. Visitors and members are encouraged to review the
          terms and privacy policies of any external websites they visit.
        </p>
        <p>
          <strong>Content Accuracy: </strong>We strive to provide accurate and
          reliable information on our website. However, we do not guarantee the
          accuracy, completeness, or timeliness of the information provided. The
          content on our website is for informational purposes only and should
          not be considered professional advice. Users and members are
          encouraged to verify the information independently and consult with
          appropriate experts or professionals when necessary.
        </p>
        <p>
          <strong>Limitation of Liability: </strong>Under no circumstances shall
          the industrial association, its officers, directors, employees, or
          agents be liable for any direct, indirect, incidental, special,
          consequential, or punitive damages arising from the use of our website
          or membership in the association. This includes but is not limited to
          damages for loss of profits, data, or business opportunities, even if
          advised of the possibility of such damages.
        </p>
        <p>
          <strong>Membership Termination: </strong>The industrial association
          reserves the right to terminate any membership at its discretion, with
          or without cause, and without prior notice. Reasons for termination
          may include but are not limited to violation of association rules,
          code of conduct, or applicable laws.
        </p>
        <p>
          <strong> Privacy and Data Protection: </strong> We are committed to
          protecting the privacy and personal data of our website visitors and
          members. Please refer to our Privacy Policy for details on how we
          collect, use, and protect personal information.
        </p>
        <p>
          By accessing and using our website or becoming a member of our
          industrial association, you agree to be bound by this disclaimer. If
          you do not agree with any part of this disclaimer, please refrain from
          using our website or participating in the membership program. The
          industrial association reserves the right to modify this disclaimer at
          any time without prior notice. It is your responsibility to review
          this disclaimer periodically for any changes or updates.
        </p>
        <p>
          If you have any questions or concerns regarding this disclaimer or our
          website, please contact us using the provided contact information.
        </p>
      </Style>
      <Footer />
    </>
  );
}

export default Disclaimer

const Style = styled.div`
padding: 4rem 5rem;
.disclaimer-heading {
text-align: center;
font-size: 2.5rem;
}
@media screen and (max-width:500px ){
    padding: 4rem 2rem;
}
`