import React from "react";
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";

const CancellationRefund = () => {
  return (
    <>
    <Navbar/>
    <div>
      <article
        className="markdown-body entry-content container-lg"
        itemProp="text"
        style={{ maxWidth: "900px", margin: "5rem auto", height: "100vh" }}>
        <h2 dir="auto">
          <a
            id="user-content-privacy-policy"
            className="anchor"
            aria-hidden="true"
            href="#privacy-policy"></a>
          Cancellation & Refund Policy
        </h2>
        <p>Last updated on Apr 2023</p>
        <p dir="auto">No cancellations & Refunds are entertained</p>
      </article>
    </div>
    <Footer/>
    </>
  );
};

export default CancellationRefund;
