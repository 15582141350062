import React, { useEffect } from "react";
import { MemberDetailSectionStyle } from "./Organisms.style";
import axios from "../../api/axios";

const MemberDetailSection = () => {
  const handleClick = async () => {
    const response = await axios.get("protected/");
    alert(JSON.stringify(response.data));
  };
  console.log("HELLOOO");
  return (
    <>
      <MemberDetailSectionStyle>
        <div className="profile-info flexbox">
          <div className="profile-info-inner view-width flexbox-space-bet-start">
            <div className="profile-left flexbox-start">
              <div className="profile-picture-wrapper profile-picture-large flexbox">
                <div className="profile-picture-inner flexbox">
                  <img
                    className="profile-picture"
                    src="https://images.unsplash.com/photo-1601326374180-e118ac4f7af4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                    alt=""
                  />
                </div>
                <div className="profile-picture-background"></div>
              </div>
              <div className="profile-username-wrapper flexbox-col-start">
                <h3 className="profile-username flexbox">
                  <span className="name">Areal Alien</span>
                  <span className="material-icons-round">verified</span>
                </h3>
                <div className="profile-followers profile-followers-desk flexbox">
                  <p>
                    <span className="posts-amount"></span>Posts
                  </p>
                  <p>
                    <span className="followers-amount"></span>Followers
                  </p>
                  <p>
                    <span className="following-amount"></span>Following
                  </p>
                </div>
                <div className="profile-bio">
                  <p className="profile-bio-inner">
                    <span className="line">All the images used are</span>
                    <span className="line">
                      from{" "}
                      <a
                        className="profile-bio-link-inner"
                        href="https://www.unsplash.com"
                        target="_blank">
                        unsplash.com
                      </a>
                    </span>
                    <span className="line">
                      <a
                        className="profile-bio-link"
                        href="https://www.arealalien.com"
                        target="_blank">
                        www.arealalien.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="profile-right flexbox-start">
              <button>Edit Profile</button>
            </div>
          </div>
        </div>
      </MemberDetailSectionStyle>
    </>
  );
};

export default MemberDetailSection;
