import React, { useEffect, useState } from "react";
import EventDetailSection from "../organisms/EventDetailSection";
import Footer from "../organisms/Footer";
import Navbar from "../organisms/Navbar";
import { connect } from "react-redux";
import { fetchEvent } from "../../redux/actions/events";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { EventDetailStyle } from "./Page.style";
// 35  62
const EventDetail = () => {
  const { id } = useParams();
  const { events } = useSelector((state) => ({ events: state.events.event }));

  const {
    title,
    description,
    agenda,
    end_day,
    end_time,
    event_image,
    location,
    start_day,
    start_time,
  } = events;

  const dispatch = useDispatch();
  console.log("events id", events);

  const fetchEventDetail = async () => {
    dispatch(fetchEvent(id));
  };
  useEffect(() => {
    if (id) {
      fetchEventDetail();
    }
  }, [id]);

  return (
    <>
    <Navbar/>
      <EventDetailStyle>
        {Object.keys(events).length === 0 ? (
          <div>Loading...</div>
        ) : (
          <div>
            <div></div>
            <div>
              <div className="image">
                <img className="card-header" src={event_image} alt="" />

                <div className="text">
                  <p>
                    {/*this.props.event.location */}
                    <br />
                    Date: {start_day} <br />
                    Time: {start_time}
                  </p>
                </div>
              </div>
              <div className="content">
                <div className="left-content">
                  <h2>{title}</h2>
                  <p>{description}</p>
                </div>
                <div className="right-content">
                  <div className="right-card-one">
                    <div className="right-card-one-date">
                      <h2>Date and Time</h2>
                      <p>Date: {start_day}</p>
                      <p>Time: {start_time}</p>
                    </div>
                    <div className="right-card-one-venue">
                      <h2>Venue / Address</h2>
                      <p>{location}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </EventDetailStyle>
      <Footer/>
    </> //
  );
};

// function apStateToProps(state, ownProps){
// const params = useParams();
// const {id} = ownProps.match.params.id;

// console.log("ownpropsid", {id});
// return {
//   event: state.events[id],
// };
// };

// function mapStateToProps(state, ownProps){
//   const {id} = params.id;
//   return {
//     event: state.events[id],
//   };

export default EventDetail;
