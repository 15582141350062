import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { BlogDetailSectionStyle } from "./Organisms.style";

const BlogDetailSection = () => {
  const { id } = useParams();
  //   const post = blog[id];
  const [blog, setBlog] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log("blog", blog);

  const fetchBlogdetail = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/blogdetail/${id}`
    );
    if (response.status === 200) {
      setBlog(response.data);
      setIsLoading(false);
      setError(null);
    } else {
      setIsLoading(false);
      setError(error.message);
    }

  };
  useEffect(() => {
    if (id) {
      fetchBlogdetail();
    }
  }, [id]);

  return (
    <>
      <BlogDetailSectionStyle>
        <div className="container">
        {isLoading && (
          <ClipLoader size={30} color={"#0099d9"} loading={isLoading} />
        )}
          <div className="card" key={id}>
            <div className="image">
              <img src={blog && blog.thumbnail} alt="" />
            </div>
            <div className="text">
              <h1>{blog && blog.title}</h1>
              <p>{blog && blog.date_created}</p>
              <h4>{blog && blog.content}</h4>
            </div>
          </div>
        </div>
      </BlogDetailSectionStyle>
    </>
  );
};

export default BlogDetailSection;
