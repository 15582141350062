import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategories, listProfile } from "../../redux/actions/listProfile";
import { TestProfileListStyle } from "../organisms/Organisms.style";
import TitleSectionThree from "../molecules/TitleSectionThree";
import DropdownSelect from "../molecules/DrpdownSelect";
import DrpdownSelectTwo from "../molecules/DrpdownSelectTwo";
import DrpdownSelectThree from "../molecules/DrpdownSelectThree";
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import ProfileCard from "../molecules/ProfileCard";
import Loader from "../molecules/Loader";
import { listServiceProvider } from "../../redux/actions/serviceProvider";
import ServiceProviderSearch from "../organisms/ServiceProviderSearch";
import ServiceProviderCard from "../molecules/ServiceProviderCard";
import DropdownSelectService from "../molecules/DrpdownSelectService";
import NotFound from "../../assets/not-found.png"

const ServiceProvider = () => {
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const categories = useSelector((state) => state.categoryReducer.categories);
  const profiles = useSelector((state) => state.serviceProviderReducer.services);
  const dispatch = useDispatch();

  const prevFiltersRef = useRef({ category: null, city: null, area: null });

  // Save filters to local storage
  const saveFiltersToLocalStorage = (filters) => {
    localStorage.setItem('membersFilters', JSON.stringify(filters));
  };

  // Retrieve filters from local storage
  const getFiltersFromLocalStorage = () => {
    const savedFilters = localStorage.getItem('membersFilters');
    return savedFilters ? JSON.parse(savedFilters) : null;
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const fetchProfiles = useCallback(() => {
    const filters = {};
    if (selectedCategory) {
      filters.service_type = selectedCategory;
    }
    if (selectedCity) {
      filters.city = selectedCity;
    }
    if (selectedArea) {
      filters.area = selectedArea;
    }

    setIsLoading(true);
    dispatch(listServiceProvider(filters)).finally(() => setIsLoading(false));
  }, [selectedCategory, selectedCity, selectedArea, dispatch]);

  useEffect(() => {
    fetchProfiles(); // Fetch profiles whenever filters change
  }, [selectedCategory, selectedCity, selectedArea, fetchProfiles]);

  useEffect(() => {
    // Compare current filters with previous filters
    const prevFilters = prevFiltersRef.current;

    if (
      selectedCategory !== prevFilters.category ||
      selectedCity !== prevFilters.city ||
      selectedArea !== prevFilters.area
    ) {
      fetchProfiles(); // Fetch profiles only if filters have changed

      // Update the previous filters
      prevFiltersRef.current = {
        category: selectedCategory,
        city: selectedCity,
        area: selectedArea,
      };

      // Save current filters to local storage
      saveFiltersToLocalStorage(prevFiltersRef.current);
    }
  }, [selectedCategory, selectedCity, selectedArea, fetchProfiles]);

  // Clear filters from local storage on page reload
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('membersFilters');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const savedFilters = getFiltersFromLocalStorage();
    if (savedFilters) {
      setSelectedCategory(savedFilters.category);
      setSelectedCity(savedFilters.city);
      setSelectedArea(savedFilters.area);
    }
  }, []);

  const handleCategoryChange = (category) => {
    if (category === "Select an option") {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
    }
  };

  const handleCityChange = (city) => {
    if (city === "Select an option") {
      setSelectedCity(null);
    } else {
      setSelectedCity(city);
    }
  };

  const handleAreaChange = (area) => {
    if (area === "Select an option") {
      setSelectedArea(null);
    } else {
      setSelectedArea(area);
    }
  };

  const locationData = () => {
    if (!profiles) return [];
    return ["Select an option", ...new Set(profiles.map((profile) => profile.city).filter(Boolean))];
  };

  const serviceTypeData = () => {
    if (!profiles) return [];
    return ["Select an option", ...new Set(profiles.map((profile) => profile.service_type).filter(Boolean))];
  }
  const subLocationData = () => {
    if (!profiles) return [];
    return ["Select an option", ...new Set(profiles.map((profile) => profile.area).filter(Boolean))];
  };

  // Handle search results and apply search filter
  const filteredProfiles = searchData.length > 0
    ? searchData
    : profiles;

  return (
    <>
      <Navbar />
      <TestProfileListStyle>
        <TitleSectionThree
          title="Service Providers"
          imgUrl={`${process.env.PUBLIC_URL}/images/title-back.jpg`}
        />

        <div
          style={{
            position: "absolute",
            right: "0",
            display: "flex",
            margin: "0.1rem 3rem",
            padding: "1rem",
          }}
        >
          <ServiceProviderSearch setSearchData={setSearchData} />

          {/* <DropdownSelect
            category={["Select an option", ...categories.map((category) => category.category)]}
            onItemClick={handleCategoryChange}
            exclusive
            value={selectedCategory}
          /> */}
          <DropdownSelectService
            category={serviceTypeData()}
            onItemClick={handleCategoryChange}
            exclusive
            value={selectedCategory}
          />

          <DrpdownSelectTwo
            location={locationData()}
            onItemClick={handleCityChange}
            exclusive
            value={selectedCity}
          />
          <DrpdownSelectThree
            sublocation={subLocationData()}
            onItemClick={handleAreaChange}
            value={selectedArea}
          />
        </div>

        <div className="container">
          {isLoading && <Loader />}

          {filteredProfiles && filteredProfiles.length > 0 ? (
            <div>
              <div className="body">
                {filteredProfiles.map((profile, id) => (
                  <ServiceProviderCard profile={profile} key={id} />
                ))}
              </div>
            </div>
          ) : (
            !isLoading && (
              <div className="not-found" style={{ textAlign: "center" }}>
                <img
                  className="img2"
                  src={NotFound}
                  alt="No profiles found"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                {/* <p>No profiles found</p> */}
              </div>
            )
          )}

          {/* {filteredProfiles.length === 0 && !isLoading && <Loader />} */}
        </div>
      </TestProfileListStyle>
      <Footer />
    </>
  );
};

export default ServiceProvider;
