import styled from "styled-components";

export const EventCardStye = styled.div``;

export const MemberCardStyle = styled.div``;

export const MemberFormStyle = styled.div``;

export const Breadcurmpstyle = styled.div`
  .container {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: 3% auto;
    margin-bottom: -5%;
  }
  hr {
    width: 80%;
    margin: auto;
    height: 2px;
    color: #8080807b;
  }
  .text-container {
    width: 78%;
    margin: auto;
  }
  .text-container h1 {
    color: #f58733;
    font-family: "Courier New", Courier, monospace;
    font-size: 240%;
    margin: 1% 0%;
  }
  .text-container p {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: 200;
    color: #747171;
  }
  .text-container1 {
    width: 78%;
    margin: 3% auto;
  }
  .text-container1 h1 {
    color: #3c7ac7;
    font-family: "Courier New", Courier, monospace;
    font-size: 240%;
  }
  .text-container1 p {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: 200;
    color: #747171;
  }
  .herotext {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 5%;
    width: 40%;
    text-align: center;
    color: white;
    font-size: 180%;
    font-weight: normal;
    font-family: "Courier New", Courier, monospace;
  }

  .heroimage img {
    height: 20vh;
    width: 100%;
    object-fit: cover;
    filter: brightness(30%);
  }
  .image img {
    width: 400px;
    height: 150px;
    object-fit: cover;
    padding: 0% 5%;
    margin-top: 8%;
  }
  @media (max-width: 600px) {
    .heroimage img {
      height: 15vh;
    }
    .herotext {
      top: 25%;
    }
    .image img {
      width: 200px;
      height: 100px;
      margin-bottom: 5%;
      padding: 0% 0%;
    }
  }
  .abouttext {
    padding: 0% 3%;
  }
  .abouttext h1 {
    padding-bottom: 1%;
    font-family: "Courier New", Courier, monospace;
    font-weight: 700;
    color: #4587d8;
    font-size: 240%;
  }
  .abouttext p {
    font-family: system-ui;
    font-family: 200;
    font-size: 105%;
    margin-bottom: 15%;
  }

  .cards-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 2% auto;
  }
  .card {
    margin: 3% 2%;
    text-align: center;
    background-color: whitesmoke;
    padding: 3%;
    box-shadow: 1px 1px 10px #0f05058f;
    border-radius: 2%;
  }
  .card h2 {
    font-family: "Courier New", Courier, monospace;
    font-size: 150%;
    padding: 1%;
    color: #3c7ac7;
  }
  .card p {
    padding: 1%;
    font-family: system-ui;
  }

  @media (max-width: 1200px) {
    .container {
      flex-direction: column;
    }
    .cards-container {
      flex-direction: column;
    }
    .container {
      margin-bottom: 4%;
    }
  }
`;

export const TitleSectionThreeStyle = styled.div`
  .showcase {
    width: 100%;
    height: 25vh;
    position: relative;
    color: white;
  }

  .showcase img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    /* z-index: 99; */
  }

  .showcase .overlay {
    width: 100%;
    height: 25vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .showcase h2 {
    margin-top: 80px;
    margin-left: 12rem;
    color: #fff;
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: none;
    font-weight: 400;
  }

  .showcase p {
    margin-top: 8px;
    font-size: 1em;
    margin-left: 12rem;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    .showcase h2 {
      /* margin-top: 60px;
  font-size: 2.2em; */
      margin: 7rem 2rem 1rem;
    }

    .showcase p {
      /* margin-top: 8px;
  font-size: 1em; */
      margin-left: 2rem;
      /* color: #fff; */
    }
  }

  @media screen and (max-width: 600px) {
    .showcase h2 {
      font-size: 1.8em;
      line-height: 1em;
    }

    .showcase p {
      margin: 1rem 1rem 0.5rem;
    }
  }

  @media screen and (max-width: 900px) {
    .showcase h2 {
      /* margin-top: 60px;
  font-size: 2.2em; */
      margin-left: 2rem;
    }

    .showcase p {
      /* margin-top: 8px;
  font-size: 1em; */
      margin-left: 2rem;
      /* color: #fff; */
    }
  }
`;

export const PaginationStyle = styled.div`
  z-index: 999;
  /* * {
    user-select: none;
  }

  *:focus {
    outline: none;
  }

  html,
  body {
    height: 100%;
    min-height: 100%;
  }

  body {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding-bottom: 100px;
    margin: 0;
    background-color: #ebf3f3;
  } */

  .brd {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  }

  #info {
    top: 0;
    right: 0;
    left: 0;
    color: #2d3667;
    font-size: 16px;
    text-align: center;
    padding: 14px;
    background-color: #f3f9f9;
  }

  #app-cover {
    /* top: 0;
    right: 0;
    left: 0; */
    width: 300px;
    height: 42px;
    margin: 5px auto 0 auto;
  }

  #select-button {
    position: relative;
    height: 16px;
    padding: 12px 14px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }

  #options-view-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  #selected-value {
    font-size: 16px;
    line-height: 1;
    margin-right: 26px;
  }

  .option i {
    width: 16px;
    height: 16px;
  }

  .option,
  .label {
    color: #2d3667;
    font-size: 16px;
  }

  #chevrons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 12px;
    padding: 9px 14px;
  }

  #chevrons i {
    display: block;
    height: 50%;
    color: #d1dede;
    font-size: 12px;
    text-align: right;
  }

  #options-view-button:checked + #select-button #chevrons i {
    color: #2d3667;
  }

  .options {
    position: absolute;
    left: 0;
    width: 250px;
  }

  #options {
    position: absolute;
    top: 42px;
    right: 0;
    left: 0;
    width: 298px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
  }

  #options-view-button:checked ~ #options {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  }

  .option {
    position: relative;
    line-height: 1;
    transition: 0.3s ease all;
    z-index: 2;
  }

  .option i {
    position: absolute;
    left: 14px;
    padding: 0;
    display: none;
  }

  #options-view-button:checked ~ #options .option i {
    display: block;
    padding: 12px 0;
  }

  .label {
    display: none;
    padding: 0;
    margin-left: 27px;
  }

  #options-view-button:checked ~ #options .label {
    display: block;
    padding: 12px 14px;
  }

  .s-c {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }

  .s-c.top {
    top: 0;
  }

  .s-c.bottom {
    bottom: 0;
  }

  input[type="radio"] {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  .s-c:hover ~ i {
    color: #fff;
    opacity: 0;
  }

  .s-c:hover {
    height: 100%;
    z-index: 1;
  }

  .s-c.bottom:hover + i {
    bottom: -25px;
    animation: moveup 0.3s ease 0.1s forwards;
  }

  .s-c.top:hover ~ i {
    top: -25px;
    animation: movedown 0.3s ease 0.1s forwards;
  }

  @keyframes moveup {
    0% {
      bottom: -25px;
      opacity: 0;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes movedown {
    0% {
      top: -25px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }

  .label {
    transition: 0.3s ease all;
  }

  .opt-val {
    position: absolute;
    left: 14px;
    width: 217px;
    height: 21px;
    opacity: 0;
    background-color: #fff;
    transform: scale(0);
  }

  .option input[type="radio"]:checked ~ .opt-val {
    opacity: 1;
    transform: scale(1);
  }

  .option input[type="radio"]:checked ~ i {
    top: 0;
    bottom: auto;
    opacity: 1;
    animation: unset;
  }

  .option input[type="radio"]:checked ~ i,
  .option input[type="radio"]:checked ~ .label {
    color: #fff;
  }

  .option input[type="radio"]:checked ~ .label:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  #options-view-button:not(:checked)
    ~ #options
    .option
    input[type="radio"]:checked
    ~ .opt-val {
    top: -30px;
  }

  .option:nth-child(1) input[type="radio"]:checked ~ .label:before {
    background-color: #000;
    border-radius: 4px 4px 0 0;
  }

  .option:nth-child(1) input[type="radio"]:checked ~ .opt-val {
    top: -31px;
  }

  .option:nth-child(2) input[type="radio"]:checked ~ .label:before {
    background-color: #ea4c89;
  }

  .option:nth-child(2) input[type="radio"]:checked ~ .opt-val {
    top: -71px;
  }

  .option:nth-child(3) input[type="radio"]:checked ~ .label:before {
    background-color: #0057ff;
  }

  .option:nth-child(3) input[type="radio"]:checked ~ .opt-val {
    top: -111px;
  }

  .option:nth-child(4) input[type="radio"]:checked ~ .label:before {
    background-color: #32c766;
  }

  .option:nth-child(4) input[type="radio"]:checked ~ .opt-val {
    top: -151px;
  }

  .option:nth-child(5) input[type="radio"]:checked ~ .label:before {
    background-color: #f48024;
  }

  .option:nth-child(5) input[type="radio"]:checked ~ .opt-val {
    top: -191px;
  }

  .option:nth-child(6) input[type="radio"]:checked ~ .label:before {
    background-color: #006400;
    border-radius: 0 0 4px 4px;
  }

  .option:nth-child(6) input[type="radio"]:checked ~ .opt-val {
    top: -231px;
  }

  .option .fa-codepen {
    color: #000;
  }

  .option .fa-dribbble {
    color: #ea4c89;
  }

  .option .fa-behance {
    color: #0057ff;
  }

  .option .fa-hackerrank {
    color: #32c766;
  }

  .option .fa-stack-overflow {
    color: #f48024;
  }

  .option .fa-free-code-camp {
    color: #006400;
  }

  #option-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 40px;
    transition: 0.3s ease all;
    z-index: 1;
    display: none;
  }

  #options-view-button:checked ~ #options #option-bg {
    display: block;
  }

  .option:hover .label {
    color: #fff;
  }

  .option:nth-child(1):hover ~ #option-bg {
    top: 0;
    background-color: #000;
    border-radius: 4px 4px 0 0;
  }

  .option:nth-child(2):hover ~ #option-bg {
    top: 40px;
    background-color: #ea4c89;
  }

  .option:nth-child(3):hover ~ #option-bg {
    top: 80px;
    background-color: #0057ff;
  }

  .option:nth-child(4):hover ~ #option-bg {
    top: 120px;
    background-color: #32c766;
  }

  .option:nth-child(5):hover ~ #option-bg {
    top: 160px;
    background-color: #f48024;
  }

  .option:nth-child(6):hover ~ #option-bg {
    top: 200px;
    background-color: #006400;
    border-radius: 0 0 4px 4px;
  }
`;

export const DropdownSelectStyle = styled.div`
  /* .container {
    width: 100%;
    height: 100vh;
  } */

  .dropdown {
    width: 250px;
    user-select: none;
    margin: 10px 0.5rem;
    position: relative;
  }

  .dropdown .dropdown-btn {
    padding: 15px 20px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    color: #333;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    height: 50px;
  }

  .dropdown .dropdown-content {
    position: absolute;
    /* top: 110%; */
    left: 0;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: #333;
    width: 100%;
    z-index: 10;
    /* box-sizing: border-box; */
  }
  .dropdown .dropdown-content .dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .dropdown .dropdown-content .dropdown-item:hover {
    background: #f4f4f4;
  }
`;

export const ProfileCardStyle = styled.div`
  h3 {
    margin: 10px 0;
  }

  h6 {
    margin: 5px 0;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    line-height: 21px;
  }

  .card-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
    color: var(--clr-dark-three);
    padding-top: 30px;
    position: relative;
    width: 310px;
    max-width: 100%;
    text-align: center;
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-container .round {
    border: 1px solid var(--clr-primary);
    border-radius: 50%;
    padding: 7px;
    height: 135px;
    width: 135px;
    /* left: 2px;
  right: 2px;
  top: 2px; */
  }
  .truncate {
    /* width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    margin: auto;
    width: 50%;
    /* border: 3px solid green; */
    padding: 10px;
  }
  .card-container p {
    text-align: center;
  }

  button.primary {
    background-color: var(--clr-primary);
    border: 1px solid var(--clr-primary);
    border-radius: 3px;
    color: #231e39;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    padding: 10px 25px;
  }

  button.primary.ghost {
    background-color: transparent;
    color: #02899c;
  }

  .skills {
    background-color: #1f1a36;
    text-align: left;
    padding: 15px;
    margin-top: 30px;
  }

  .skills ul {
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .skills ul li {
    border: 1px solid #2d2747;
    border-radius: 2px;
    display: inline-block;
    font-size: 12px;
    margin: 0 7px 7px 0;
    padding: 7px;
    color: var(--clr-white);
  }

  footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
  }

  footer p {
    margin: 10px 0;
  }

  footer i {
    color: red;
  }

  footer a {
    color: #3c97bf;
    text-decoration: none;
  }
`;

export const TitleSectionMembersStyle = styled.div`
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 10;
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    display: none;
  }
  .image {
    width: 100%;
    margin: 0% auto;
  }
  .image img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    margin: auto;
  }

  .text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 5%;
    width: 40%;
    text-align: center;
  }

  .text h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.7)
    );
    /* background: rgba(0, 0, 0, 0.5); */
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
    color: white;
    padding: 6%;
    margin: 6%;
    font-size: 4rem;
  }
  @media (max-width: 500px) {
    .text {
    background-image: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.7));
    /* background: rgba(0, 0, 0, 0.5); */
    width: 60%;
    border-radius: 0.2rem;
    color: white;
    padding: 2rem 4rem;
    margin: 6%;

    /* background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s; */
  }
`;
