import styled from "styled-components";

export const EventDetailStyle = styled.div`
  .image {
    width: 100%;
    margin: 0% auto;
  }
  .image img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    margin: auto;
  }

  .text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 5%;
    width: 40%;
    text-align: center;
  }

  .text p {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    color: white;
    padding: 6%;
    margin: 6%;
    font-size: 1.4rem;
  }
  .content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 2rem;
    margin: 2rem 7rem;
    justify-content: center;
    align-items: baseline;
  }
  .content h2 {
    padding-bottom: 1%;
    font-weight: 500;
  }
  .content p {
    padding-bottom: 3%;
    padding-right: 5%;
  }

  .left-content {
    margin: 0 1rem;
  }

  .right-card-one {
    background-color: #f8f8f8;
    padding: 1rem;
    text-align: center;
  }
  .right-card-one h2 {
    padding: 1rem;
    color: var(--clr-secondary);
  }

  .right-card-one-date {
    padding-bottom: 1rem;
  }

  @media (max-width: 900px) {
    .image img {
      height: 50vh;
    }
    .text p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 600px) {
    .content {
      display: grid;
      grid-template-columns: 1fr;
      margin: 2rem 1rem;
      padding: 0 1rem;
      justify-content: center;
      align-items: center;
    }
    .left-content {
      margin: 0;
    }
    .image img {
      height: 46vh;
    }
    .content {
      width: 90%;
    }
    .image {
      width: 100%;
    }
    .text p {
      font-size: 1rem;
    }
  }
`;

export const CreateProfileStyle = styled.div`
  .profile-picture {
    width: 150px;
  }
  .input-group_fields {
    display: grid;
    grid-template-columns: 1fr ;
  }
  .forms textarea {
    margin-top: 6%;
    border: none;
    border-bottom: 2px solid;
    border-color: var(--clr-primary);
    width: 100%;
    padding-left: 8%;
    margin-left: 5%;
    padding-top: 5%;
    font-size: 16px;
  }
  .container {
    position: relative;
    max-width: 1000px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 5% auto;
  }

  .container .forms {
    width: 100%;
    display: flex;

    align-items: center;
    transition: height 0.4s ease;
  }

  .container .form {
    width: 100%;
    padding: 2rem 2rem;
    border-radius: 12px;
    background: #fff;
    transition: margin-left 0.36s ease;
  }

  .container.active .login {
    margin-left: -50%;
    opacity: 0;
    transition: margin-left 0.36s ease, opacity 0.3s ease;
  }

  .container.active .login {
    opacity: 0;
    transition: margin-left 0.36s ease, opacity 0.3s ease;
  }

  .container .signup {
    opacity: 0;
    transition: opacity 0.18s ease;
  }
  .forms label {
    color: gray;
  }

  .container.active .signup {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  .container.active .forms {
    height: 600px;
  }

  .container .form .title {
    position: relative;
    font-size: 27px;
    font-weight: 600;
  }

  .form .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background-color: #4070f4;
    border-radius: 25px;
  }

  .form .input-field {
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: 30px;
  }

  .input-field input {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 35px;
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 2px solid #ccc;
    border-top: 2px solid transparent;
    transition: border-bottom-color 0.4s ease;
  }

  .input-field input:is(:focus, :valid) {
    border-bottom-color: #4070f4;
  }

  .input-field i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 23px;
    transition: color 0.4s ease;
  }

  .input-field input:is(:focus, :valid) ~ i {
    color: #4070f4;
  }

  .input-field i.icon {
    left: 0;
  }

  .input-field i.showHidePw {
    right: 0;
    cursor: pointer;
    padding: 10px;
  }

  .form .checkbox-text {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }

  .checkbox-text .checkbox-content {
    display: flex;
    align-items: center;
  }

  .checkbox-content input {
    margin: 0 8px -2px 4px;
    accent-color: #4070f4;
  }

  .form .text {
    color: #333;
    font-size: 14px;
  }

  .form a.text {
    color: #4070f4;
    text-decoration: none;
  }

  .form a {
    text-decoration: none;
  }

  .form a:hover {
    text-decoration: underline;
  }

  .form .button {
    margin-top: 35px;
  }

  .form .button input {
    border: none;
    color: white;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 6px;
    background-color: #4070f4;
    cursor: pointer;
    transition: all 0.6s ease;
  }

  .button input:hover {
    background-color: #4070f4;
  }

  .form .login-signup {
    margin-top: 30px;
    text-align: center;
  }

  @media (max-width: 700px) {
    .input-group_fields {
      display: block;
    }
  }
`;
