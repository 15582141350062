import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../organisms/Footer";
import Navbar from "../organisms/Navbar";
import { baseURL } from "../../api/axios";
import NewsMediaComp from "../organisms/NewsMediaComp";

const NewsMedia = () => {
  const [media, setMedia] = useState([]);

  const fetchMedia = () => {
    axios
      .get(`${baseURL}/api/news`)
      .then((response) => {
        const allMedia = response.data;
        setMedia(allMedia);
      })
      .catch((error) => console.error(error));
  };
useEffect(() => {
  fetchMedia()

}, [])

  return (
    <>
      <Navbar />
        <NewsMediaComp media={media}/>
      <Footer />
    </>
  );
};

export default NewsMedia;
