export default [
  {
    title: "Connecting business opportunity in a better way",
    description: "Tailored as per your need for better results",
    urls: "../images/hero.jpg",
    link: "choose-us",
    btntext: "Learn More",
  },
  {
    title: "Connecting Business Globally ",
    description:
      "We are giving digital business solutions to all major industries.",
    urls: "../images/heroimg.jpg",
    link: "Know More",
    btntext: "Know More",
  },

];
